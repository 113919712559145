import React, { useState } from 'react'
// import Button from './../../buttons/Button'
import './Form.scss'
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export const Form = ({ thissetStep, seturlParam }) => {

    const { t, i18n } = useTranslation();
    const { focusName, setfocusName } = useState(false);
    // const { focuspromo, setfocuspromo } = useState(false);
    const [newError, setNewError] = useState('');
    const { focusEmail, setfocusEmail } = useState(false);
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const URLtoSend = `${process.env.REACT_APP_API}/registrationdata`;
    const onSubmit = (data) => {
        axios.post(URLtoSend, {
            name: data.Name,
            email: data.Email,
            url: process.env.REACT_APP_SITE,
            gender: data.gender
        }).then((response) => {
            thissetStep(4);
            seturlParam(response.data._id);
            setNewError('');
        }).catch(function (error) {
            // let promo = error.response.data.message === 'Promocode disabled' ? t('promodisabled') : t('errorform');
            // setNewError(promo);
        });
    }

    const options = [
        { value: process.env.REACT_APP_WIFE, label: t('woman') },
        { value: process.env.REACT_APP_GIRLFRIEND, label: t('girl') },
        { value: process.env.REACT_APP_HUSBAND, label: t('man') },
        { value: process.env.REACT_APP_BOYFRIEND, label: t('boy') }
    ];

    function onFocusName() {
        setfocusName(true);
    }
    function onFocusEmail() {

        setfocusEmail(true);
    }
    // function onFocuspromo() {

    //     setfocuspromo(true);
    // }
    return (
        <div className="form-element" >

            <form id='main-form' onSubmit={handleSubmit(onSubmit)}>

                <div
                    className={errors.Name ? 'error-type focus_this' : 'focus_this'}
                >
                    <span className={focusName ? 'form-element__label show' : 'form-element__label'}>{t('your_name')}</span>
                    <input
                        className={focusName ? ' color_black form-element__field ' : 'form-element__field'}
                        onChange={onFocusName}
                        type='text'
                        placeholder={errors.Name ? t('need') : t('name')}
                        {...register("Name", {
                            required: true
                        })}

                    />

                </div>

                {/* <div
                    className={errors.Lastname ? 'error-type focus_this' : 'focus_this'}
                >
                    <span className={focusName ? 'form-element__label show' : 'form-element__label'}>{t('your_surname')}</span>
                    <input
                        className={focusName ? ' color_black form-element__field ' : 'form-element__field'}
                        onChange={onFocusName}
                        type='text'
                        placeholder={errors.Lastname ? t('need') : t('surname')}
                        {...register("Lastname", {
                            required: true
                        })}

                    />

                </div> */}


                <div
                    className={errors.Email ? 'error-type focus_this' : 'focus_this'}
                >
                    <span className={focusEmail ? 'form-element__label show' : 'form-element__label'}>{t('your_email')}</span>
                    <input
                        onChange={onFocusEmail}
                        className='form-element__field'
                        type='text'
                        placeholder='Email'
                        {...register("Email", {
                            required: true,
                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })}
                    />
                </div>
                {/* <div
                    className={errors.Promocode ? 'error-type focus_this' : 'focus_this'}
                >
                    <span className={focuspromo ? 'form-element__label show' : 'form-element__label'}>{t('promocode')}</span>
                    <input
                        className={focuspromo ? ' color_black form-element__field ' : 'form-element__field'}
                        onChange={onFocuspromo}
                        type='text'
                        placeholder={errors.Promocode ? t('need') : t('promocode')}
                        {...register("promo", {
                            required: false
                        })}

                    />

                </div> */}
                <Controller
                    control={control}
                    name="gender"
                    render={({ field: { onChange, value, ref } }) => (
                        <Select
                            ref={ref}
                            placeholder={t('select')}
                            className='react-select-container'
                            classNamePrefix="react-select"
                            options={options}
                            value={options.find(c => c.value === value)}
                            onChange={val => onChange(val.value)}

                        />
                    )
                    }


                />
                {errors.Email && <p className='error_message'>{t('wrongemail')}</p>}
                {newError && <p className='error_message'>{newError}</p>}
            </form>
        </div>
    )
}
