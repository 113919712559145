import React, { useState, useRef } from 'react'
import './HowWorks.scss';
import Button from '../buttons/Button';
import arrow from './../../assets/Howitworks/arrow.svg';
import illustration from './../../assets/Howitworks/illustration.png';
import illustrationMob from './../../assets/Howitworks/illustration_mob.png';
import icon_1 from './../../assets/Howitworks/icon_1.png';
import icon_2 from './../../assets/Howitworks/icon_2.png';
import icon_3 from './../../assets/Howitworks/icon_3.png';
import icon_4 from './../../assets/Howitworks/icon_4.png';
import line from './../../assets/Howitworks/line.svg'
import { useTranslation } from 'react-i18next';


const HowWorks = ({ setShowModal }) => {
  const HowWorksRef = useRef()
  const {t,i18n} = useTranslation();
  // const [showModal, setShowModal] = useState(false);
  return (
    <section id="HowWorks" ref={HowWorksRef} className="block__itWorks">
      <div className="container">
        <div className="block__itWorks__header">

          <img src={line} className='block__itWorks__header__img' alt="line" />
          <h2 className="block__itWorks__header__title">{t("HOW")}</h2>
          <p className="block__itWorks__header__description">{t("description_how")}</p>
        </div>
        <div className="block__itWorks__main">
          <div className="block__itWorks__main__picture">
            <img src={illustration} alt="" className="block__itWorks__main__picture__image" />
            <img src={illustrationMob} alt="" className="block__itWorks__main__picture__image-mob" />
          </div>
          <div className="block__itWorks__main__info">
            <h3 className="block__itWorks__main__info__title">{t("list_title")}</h3>
            <div className="block__itWorks__main__info__items">
              <div className="block__itWorks__main__info__item">
                <img src={icon_1} alt="icon_1" className="block__itWorks__main__info__item__img" />
                <p className="block__itWorks__main__info__item__description">{t("eyes")}</p>
              </div>
              <div className="block__itWorks__main__info__item">
                <img src={icon_2} alt="icon_2" className="block__itWorks__main__info__item__img" />
                <p className="block__itWorks__main__info__item__description">{t("face")}</p>
              </div>
              <div className="block__itWorks__main__info__item">
                <img src={icon_3} alt="icon_3" className="block__itWorks__main__info__item__img" />
                <p className="block__itWorks__main__info__item__description">{t("response")}</p>
              </div>
              <div className="block__itWorks__main__info__item">
                <img src={icon_4} alt="icon_4" className="block__itWorks__main__info__item__img" />
                <p className="block__itWorks__main__info__item__description">{t("heart")}</p>
              </div>

            </div>
            <div className="block__itWorks__main__info__button">

              <Button id='Popup_second_screen' onClick={() => setShowModal(true)}>{t("main_sec_button")}</Button>
              <img src={arrow} className='block__itWorks__main__button__arrow' alt="arrow" />
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default HowWorks