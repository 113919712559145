import React from 'react'
import first from './../../assets/main_page/first.png'
import second from './../../assets/main_page/second.png'
import third from './../../assets/main_page/third.png'
import fourth from './../../assets/main_page/fourth.png'
import './Benefits.scss'
import { useTranslation } from "react-i18next";
const Benefits = () => {
    const {t,i18n} = useTranslation();
    return (
        <section className="Benefit">
            <div className="container">
                <h2 className="Benefit__title">{t("why_title")}</h2>
                <div className='Benefit__list'>
                    <div className="Benefit__list__column">
                        <div className='Benefits__list__item'>
                            <img alt='icon' className='Benefits__list__item__image' src={first}></img>
                            <p className='Benefits__list__item__description'>{t("dostovernost")}</p>
                        </div>
                    </div>
                    <div className="Benefit__list__column">
                        <div className='Benefits__list__item'>
                            <img alt='icon' className='Benefits__list__item__image' src={second}></img>
                            <p className='Benefits__list__item__description'>{t("results")}</p>
                        </div>
                    </div>
                    <div className="Benefit__list__column">
                        <div className='Benefits__list__item'>
                            <img alt='icon' className='Benefits__list__item__image' src={third}></img>
                            <p className='Benefits__list__item__description'>{t("whatto")}</p>
                        </div>
                    </div>
                    <div className="Benefit__list__column">
                        <div className='Benefits__list__item'>
                            <img alt='icon' className='Benefits__list__item__image' src={fourth}></img>
                            <p className='Benefits__list__item__description'>{t("price")}</p>
                        </div>
                    </div>

                </div >
            </div >
        </section >

    )
}

export default Benefits