import { Route, Routes } from 'react-router-dom';
import React, { useState } from "react";
import Layout from './components/Layout';
import Table from './components/Result/Table/Table';
import { Politica } from './components/Politica/Politica';
import Oferta from './components/Oferta/oferta';
import Calibration from './pages/Calibration/Calibration';
import Camera from './pages/Camera/Camera';
import makefemale from './assets/popups/68.jpeg'
import Eyetracker from './pages/Eyetracker/Eyetracker';
import FormRegistrate from './pages/FormRegistrate/FormRegistrate';
import ConfigurePage from './pages/ConfigurePage/ConfigurePage';
import Diagnose from './pages/Diagnose/Diagnose';
import FinalTest from './pages/FinalTest/FinalTest';
import Home from './pages/Home/Home';
import Instruction from './pages/Instruction/Instruction';
import { PartnerResult } from './components/Result/PartnerResult';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalNoGo } from './components/popup/Popup';
import Button, { ButtonIcon } from './components/buttons/Button';
import TestResult from './components/Result/TestResult/TestResult';
import { Form } from './components/popup/Form/Form';
import copy from './assets/popups/copy.svg'
import TableId from './components/Result/Table/TableId';
import Payment from './pages/Payment/Payment'; 
import { useTranslation } from 'react-i18next';



function App() {
  let locallanguage = localStorage.getItem('language');
  if(locallanguage === null){
    localStorage.setItem('language', 'uk')
  }; 
  const {t,i18n} = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(2);
  const [Copied, setCopied] = useState(t('copybutton'));
  const [urlParam, seturlParam] = useState('');
  const URLtoCOPY = `${process.env.REACT_APP_SITE}/instruction/${urlParam}`;
  const goNext = () => {
    window.location.href = URLtoCOPY;
  }
  const copyText = async () => {
    await navigator.clipboard.writeText(URLtoCOPY);
    setCopied(t('copybutton_result'));
  }
  
  return (
<>
      <Routes>

        <Route path='/' element={<Layout showModal={showModal} setShowModal={setShowModal} />}>
          <Route index element={<Home showModal={showModal} setShowModal={setShowModal} />} />
          <Route path='/:id' element={<Home showModal={showModal} setShowModal={setShowModal} />} />
          <Route path="demo" element={<TestResult showModal={showModal} setShowModal={setShowModal} />} />
          <Route path="configure" element={<ConfigurePage />} />
          <Route path="registrate/:id" element={<FormRegistrate />} />
          <Route path="quiz" element={<Diagnose />} />
          <Route path="quiz/:id" element={<Diagnose />} />
          <Route path="politica" element={<Politica />} />
          <Route path="oferta" element={<Oferta />} />
          <Route path="test" element={<FinalTest />} />
          <Route path="instruction/:id" element={<Instruction />} />
          <Route path="instruction" element={<Instruction />} />
          <Route path="result/:id" element={<Table />} />
          <Route path="end/:id" element={<TableId />} />
          <Route path="finish/:id" element={<PartnerResult />} />
          <Route path="calibration" element={<Calibration />} />
          <Route path="camera/:id" element={<Camera />} />
          <Route path="tracker" element={<Eyetracker />} />
          <Route path="tracker/:id" element={<Eyetracker />} />
          <Route path="camera" element={<Camera />} />
          <Route path="payment" element={<Payment />} />
        </Route>
      </Routes>
      {step === 2 ? <Modal
        show={showModal}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h4 style={{ marginBottom: "20px" }}>{t("popup_title")}</h4>
          <img style={{ width: '140px', margin: '0 auto', borderRadius: '15px' }} src={makefemale} />
          <h6>{t("popup_description")}</h6>
        </ModalHeader>
        <ModalBody>
          <Form thissetStep={setStep} seturlParam={seturlParam} />
   
        </ModalBody>
        <ModalFooter>
          <Button className='btn-violet' style={{ margin: '0 auto' }} form='main-form'>{t("popup_main_button")}</Button>
        </ModalFooter>
      </Modal>
        : null}

      {step === 4 && <ModalNoGo
        show={showModal}
        setShow={setShowModal}
      >

        <ModalHeader>
          <h3 className='modal-h3'>{t("popup_titlefinish")}</h3>
        </ModalHeader>
        <ModalBody>
          <p className='modal-p'>{t("popup_descriptionfinish")}</p>
        </ModalBody>
        <ModalFooter>
          <div className='buttons__elements'>
            <ButtonIcon className='btn-grey' onClick={copyText} icon={copy} classNameIcon='icon_padding'>{Copied}</ButtonIcon>
            <Button className='btn-vilet' onClick={goNext} >{t("nextbutton")}</Button>
          </div>
        </ModalFooter>
      </ModalNoGo>
      }
      </>
  );
}

export default App;

