import React from 'react'
import '../Oferta/oferta.scss'
export const Politica = () => {
  return (
    <>
      <section id="ItWork" className="block_testing">
        <div className="container">
          <div className="block_testing__header">
            <h2 className="block_testing__header__title">ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</h2>
          </div>
          <div className="block_testing__main">
            <div className='document_div'>
              <p>
                Ця Політика конфіденційності, далі - Політика, діє щодо обробки персональних даних, які може отримати ФОП &ldquo;Алексанкін І.С.&rdquo; від фізичних осіб (суб'єктів персональних даних) на підставі Конституції України та Закону України &ldquo;Про захист персональних даних&rdquo; від 01.06.2010 р. №2297-VI (далі - Закон), а також Генерального регламенту захисту даних (ЄС) 2016/679 від 27.04.2016 р. (EU General Data Protection Regulation, далі - GDPR) та іншого застосовного Європейського законодавства про захист даних, що називається далі - Законодавство.
              </p>
              <p>Ця Політика конфіденційності розроблена, щоб розповісти Вам:</p>
              <ol>
                <li>що таке персональні дані;</li>
                <li>які Ваші персональні дані ми збираємо;</li>
                <li>як і чому ми використовуємо їх;</li>
                <li>кому ми передаємо Ваші персональні дані;</li>
                <li>як ми захищаємо конфіденційність Ваших персональних даних;</li>
                <li>як з нами зв'язатися та до кого звернутися, якщо у Вас виникнуть питання щодо обробки Ваших персональних даних.</li>
              </ol>
              <p>Ми обробляємо Ваші персональні дані лише у випадку, якщо виконана одна з умов, зазначених у статті 6 GDPR, у тому числі, але не виключно:</p>
              <ol>
                <li>Ви надали згоду на обробку Ваших персональних даних;</li>
                <li>обробка необхідна з метою надання Вам послуг;</li>
                <li>така обробка потребується законодавством країн, де Ви перебуваєте.</li>
              </ol>
              <p>Ми дійсно серйозно ставимося до безпеки персональних даних наших клієнтів, потенційних клієнтів та інших осіб, які звернулися до нас, тому прагнемо захищати конфіденційність Ваших персональних даних. Адміністрація зобов'язується вжити всіх необхідних заходів для запобігання зловживанню Вашими персональними даними, які стають нам відомими. Ми будемо обробляти Ваші персональні дані у суворій відповідності до вимог застосовного Законодавства та виключно за наявності законних підстав на таку обробку.</p>
              <p>Ви не зобов'язані надавати нам персональні дані, але без певної інформації про Вас ми не зможемо надати Вам деякі наші послуги. У випадку, якщо ми контролюємо способи збору Ваших персональних даних та визначаємо цілі, для яких ці персональні дані використовуються, Адміністрація є &ldquo;контролером персональних даних&rdquo; для цілей GDPR та іншого застосованого європейського законодавства про захист даних, а також &ldquo;власником персональних даних&rdquo; у розумінні Закону.</p>
            </div>
            <h4><strong>1. Терміни та визначення</strong></h4>
            <div className='document_div'>
              <p><strong>Персональні дані </strong>- відомості або сукупність відомостей про фізичну особу, яка ідентифікована або може бути конкретно ідентифікована (Користувач);</p>
              <p><strong>Особливі категорії персональних даних </strong>- це так звані &ldquo;чутливі&rdquo; персональні дані, які можуть завдати шкоди суб'єкту даних на роботі, у навчальному закладі, середовищі проживання, або можуть призвести до його дискримінації в суспільстві. Наприклад, це персональні дані, які містять інформацію про расове походження, політичні чи релігійні погляди, про членство в профспілках, про стан здоров'я, сексуальне життя, біометричні чи генетичні дані. У термінології українського законодавства &ndash; це такі персональні дані, опрацювання яких несе особливий ризик для суб'єктів персональних даних;</p>
              <p><strong>Суб'єкт персональних даних </strong>- це фізична особа, до якої належать персональні дані та яку за цими персональними даними можна ідентифікувати, або яка вже ідентифікована;</p>
              <p><strong>Адміністрація сайту (Адміністрація, а також далі за текстом ми, нами, нас, нам) </strong>- ФОП &ldquo;Алексанкін І.С.&rdquo;, юридична адреса: 50000, Україна, м. Кривий Ріг, пр. Поштовий, 52, 405; адреса фактичного місця знаходження та поштова адреса: 50000, Україна, м. Кривий Ріг, пр. Поштовий, 52, 405;</p>
              <p><strong>Обробка персональних даних </strong>- будь-яка дія або сукупність дій, таких як збирання, реєстрація, накопичення, зберігання, адаптування, зміна, відновлення, використання та розповсюдження (поширення, реалізація, передача), знеособлення, знищення персональних даних, у тому числі з використанням інформаційних (автоматизованих) систем;</p>
              <p><strong>Поширення персональних даних </strong>- дії щодо передачі відомостей про фізичну особу за згодою суб'єкта персональних даних;</p>
              <p><strong>Використання персональних даних </strong>- будь-які дії Адміністрації з обробки цих даних, дії щодо їх захисту, а також дії щодо надання часткового або повного права обробки персональних даних іншим суб'єктам відносин, пов'язаних з персональними даними, що здійснюються за згодою суб'єктів персональних даних або відповідно до законодавства України;</p>
              <p><strong>Знеособлення персональних даних </strong>- вилучення відомостей, що дозволяють прямо чи опосередковано ідентифікувати особу;</p>
              <p><strong>Користувач </strong>- суб'єкт персональних даних, будь-яка дієздатна фізична особа, яка приєдналася до цієї Політики у власних інтересах в онлайн-сервісі https://besure.stimul.ua за допомогою мережі Інтернет;</p>
              <p>Онлайн-сервіс &ndash; сервіс, який належить Адміністрації та розташований за адресою https://besure.stimul.ua, призначений для виявлення реакцій організму шляхом використання штучного інтеллекту;</p>
              <p>Особистий кабінет &ndash; розділ онлайн-сервісу, доступ до якого Користувача здійснюється після його реєстрації в онлайн-сервісі https://besure.stimul.ua та запровадження унікального логіну та паролю. Особистий кабінет містить персональні дані Користувача та призначений для перегляду та керування доступними функціональними можливостями онлайн-сервісу;</p>
              <p>Реєстрація - дії Користувача щодо заповнення та надсилання реєстраційної форми, розміщеної в онлайн-сервісі, які, у разі відсутності в Адміністрації заперечень з реєстрації, мають наслідком надання доступу до Особистого кабінету;</p>
              <p>Авторизація - введення Користувачем свого логіну та пароля для входу до свого Особистого кабінету в онлайн-сервісі;</p>
              <p>Політика - ця Політика конфіденційності, розташована за адресою https://besure.stimul.ua/politica;</p>
              <p>Контролер персональних даних - це фізична чи юридична особа, яка визначає цілі та засоби для обробки персональних даних та несе головну відповідальність за їх обробку. Контролер персональних даних є "власником персональних даних" у термінології українського законодавства. У рамках цієї Політики Контролером персональних даних є ФОП &ldquo;Алексанкін І.С.&rdquo;;</p>
              <p>Процесор персональних даних - це фізична або юридична особа, яка на підставі інструкцій (вказівок, розпоряджень) обробляє персональні дані для контролера. Процесор персональних даних є "розпорядником персональних даних" у термінології українського законодавства;</p>
            </div>
            <h4>2. Загальні положення</h4>
            <div className='document_div'>
              <p>2.1. Політика застосовується до всіх Ваших персональних даних, які можуть бути отримані Нами в процесі використання Вами онлайн-сервісу. Ця Політика поширюється на персональні дані, отримані як до, так і після введення в дію цієї Політики.</p>
              <p>2.2. Мета Політики полягає в доведенні до Вас необхідної інформації, яка дозволяє оцінити, які персональні дані та з якими цілями обробляються нами, методи їх обробки та забезпечення безпеки.</p>
              <p>2.3. При використанні онлайн-сервісу, Ви, повідомивши Адміністрації свої персональні дані, у тому числі за допомогою третіх осіб, визнаєте свою згоду на обробку своїх персональних даних відповідно до цієї Політики.</p>
              <p>2.4. У разі незгоди з умовами цієї Політики Ви зобов'язані припинити використовувати онлайн-сервіс.</p>
              <p>2.5. Згода на обробку персональних даних може бути відкликана суб'єктом персональних даних. У разі відкликання суб'єктом персональних даних згоди на обробку персональних даних, Адміністрація має право продовжити обробку персональних даних без згоди суб'єкта персональних даних за наявності підстав, зазначених у законодавстві.</p>
              <p>2.6. Адміністрація сайту не перевіряє достовірність персональних даних, які надає Користувач, не має можливості оцінювати його дієздатність. Однак Адміністрація виходить з того, що Користувач діє сумлінно, обачно, надає достовірні та достатні персональні дані та докладає всіх необхідних зусиль для підтримки таких даних у актуальному стані та не порушує права третіх осіб.</p>
              <p>2.7. Погоджуючись з умовами цієї Політики, Ви підтверджуєте, що в момент збору персональних даних Ви повідомлені про осіб, яким передаються персональні дані, зміст та цілі збору персональних даних. Ви підтверджуєте (гарантуєте), що персональні дані, які передані нам на обробку, передані за згодою власників персональних даних та в рамках Законодавства.</p>
              <p>2.8. Адміністрація, отримавши персональні дані від Користувача, не приймає на себе зобов'язання щодо інформування суб'єктів (їх представників), персональні дані яких йому передані, про початок обробки персональних даних, оскільки обов'язок здійснити відповідне інформування, під час укладання договору з суб'єктом персональних даних та/або при отримання згоди на таку передачу несе Користувач, який передав персональні дані.</p>
              <p>2.9. Обробка Ваших персональних даних здійснюється відповідно до вимог Закону. Обробка персональних даних осіб, які перебувають на території ЄС або є громадянами ЄС, регулюється, зокрема, Генеральним регламентом захисту даних ЄС 2016/679 (далі - &ldquo;GDPR&rdquo;). Також законодавство інших країн може встановлювати додаткові вимоги.</p>
              <p>2.10. Ця Політика діє стосовно всієї інформації, яку Адміністрація може отримати про Користувача під час використання ним онлайн-сервісу, а також під час виконання Адміністрацією будь-яких угод та договорів з Користувачем.</p>
              <p>2.11. Ця Політика є внутрішнім документом Адміністрації.</p>
              <p>2.12. Контролер персональних даних звільняється від відповідальності за наслідки, що настали у зв'язку з обробкою ним персональних даних, якщо він не несе відповідальності за подію, що спричинила наступ таких наслідків.</p>
              <p>Ви також погоджуєтесь з тим, що Власник персональних даних має право надавати доступ та передавати Ваші персональні дані третім особам без будь-яких додаткових повідомлень, виключно, якщо при цьому не змінюється мета їх обробки та лише у випадках, передбачених цією Політикою конфіденційності та/або законодавством України.</p>
              <p>Ніхто до 18 років не повинен надавати нам персональну інформацію через Онлайн-сервіс. Ми не збираємо цілеспрямовано персональну інформацію осіб віком до 18 років. Батьки та опікуни повинні постійно контролювати пов'язану з цим діяльність своїх дітей.</p>
            </div>
            <h4>3. Склад персональних даних</h4>
            <div className='document_div'>
              <p>3.1. Адміністрація для здійснення своєї діяльності та для виконання своїх зобов'язань опрацьовує персональні дані Користувача, надані ним при реєстрації на сайті https://besure.stimul.ua та зберігаються в особистому кабінеті.</p>
              <p>3.2. До персональних даних Користувача належать: прізвище, ім'я, по-батькові, адреса електронної пошти, номер мобільного/стаціонарного телефону, країна проживання, місце роботи, посада, дата народження.</p>
              <p>Ми просимо Вас повідомляти лише ті персональні дані, які необхідні для надання вибраної Вами послуги, отримання інформаційної розсилки або відповіді на Ваш спеціальний запит/претензію. У той же час, якщо Ви вирішите повідомити додаткові персональні дані, ми також зможемо обробити їх з необхідним рівнем захисту.</p>
              <p>3.3. Адміністрація має право встановлювати вимоги до складу персональних даних, які повинні обов'язково надаватися під час використання онлайн-сервісу. Якщо певна інформація не зазначена Адміністрацією як обов'язкова, її надання чи розкриття здійснюється Користувачем на власний розсуд.</p>
              <p>3.4. Дані, які автоматично передаються Адміністрації при використанні онлайн-сервісу Користувачем за допомогою встановленого на пристрої програмного забезпечення: IP-адреса, інформація про браузер та вид операційної системи пристрою, технічні характеристики обладнання та програмного забезпечення, дата та час доступу до онлайн-сервісу.</p>
            </div>
            <h4>4. Підстави та мета обробки персональних даних</h4>
            <div className='document_div'>
              <p>4.1. Підставами для обробки персональних даних є:</p>
              <p>1) згода суб'єкта персональних даних на обробку його персональних даних Адміністрацією;</p>
              <p>2) укладання та виконання договору, однією із сторін якої є суб'єкт персональних даних або який укладений на користь суб'єкта персональних, або для здійснення заходів, що передують укладенню договору на вимогу суб'єкта персональних;</p>
              <p>3) необхідність виконання Адміністрацією вимог, передбачених Законодавством.</p>
              <p>4.2. Метою обробки персональних даних є:</p>
              <p>⦁ здійснення покладених на Адміністрацію функцій відповідно до законодавства України та GDPR;</p>
              <p>⦁ збору, зберігання та обробки персональних даних, отриманих в онлайн-сервісі в рамках Закону та GDPR;</p>
              <p>⦁ для відправлення Користувачеві комерційних (маркетингових) повідомлень, що містять додаткову інформацію про послуги, актуальні акції та спеціальні пропозиції щодо послуг, що надаються Адміністрацією за допомогою онлайн-сервісу;</p>
              <p>⦁ ідентифікація суб'єкта персональних даних під час використання онлайн-сервісу;</p>
              <p>⦁ зв'язок із суб'єктом персональних даних у разі потреби, у тому числі, направлення пропозицій, інформаційних матеріалів, повідомлень, інформації та запитів, реклами, а також обробка запитів суб'єкта персональних даних;</p>
              <p>⦁ покращення якості онлайн-сервісу, зручності його використання, розробка нових функціональних можливостей та покращення якості обслуговування;</p>
              <p>⦁ підвищення професійних навичок та кваліфікації Користувача;</p>
              <p>⦁ проведення статистичних та інших досліджень на основі знеособлених даних;</p>
              <p>⦁ виконання Адміністрацією договірних та інших зобов'язань перед Користувачем за угодами, укладеними між Адміністрацією та Користувачем або третіми особами на користь Користувача.</p>
            </div>
            <h4>5. Основні принципи обробки персональних даних</h4>
            <div className='document_div'>
              <p>5.1. Обробка персональних даних Адміністрацією здійснюється на основі принципів:</p>
              <p>5.1.1. Законності цілей та способів обробки персональних даних;</p>
              <p>5.1.2. Добросовісності Адміністрації, як власника персональних даних, досягається шляхом виконання вимог законодавства України щодо опрацювання персональних даних;</p>
              <p>5.1.3. Досягнення конкретних, наперед визначених цілей обробки персональних даних;</p>
              <p>5.1.4. Відповідності цілей обробки персональних даних цілям, заздалегідь визначеним та заявленим при зборі персональних даних;</p>
              <p>5.1.5. Відповідності переліку та обсягу оброблюваних персональних даних, а також способів обробки персональних даних заявленим цілям обробки;</p>
              <p>5.1.6. Достовірність персональних даних, їх достатності з метою обробки, неприпустимості обробки персональних даних, надлишкових стосовно цілей обробки персональних даних;</p>
              <p>5.1.7. Забезпечення при обробці персональних даних точності персональних даних, їх достатності, а в необхідних випадках та актуальність щодо цілей обробки персональних даних.</p>
              <p>5.1.8. Неприпустимість об'єднання баз даних, що містять персональні дані, обробка яких здійснюється з несумісними цілями між собою;</p>
              <p>5.1.9. Зберігання персональних даних у формі, що дозволяє визначити суб'єкта персональних даних не довше, ніж цього вимагає мета їх обробки.</p>
              <p>5.1.10. Персональні дані, що обробляються, підлягають знищенню або знеособленню по досягненню цілей обробки або у разі втрати необхідності в досягненні цих цілей, якщо інше не передбачено законодавством України та GDPR.</p>
              <p>5.1.11. Ми також повинні враховувати періоди, для яких нам, можливо, потрібно буде зберегти Ваші персональні дані для виконання наших юридичних зобов'язань перед Вами або контролюючими органами.</p>
              <p>5.1.12. Ми можемо з часом мінімізувати Ваші персональні дані, які ми використовуємо, або навіть можемо зробити Ваші дані анонімними, щоб вони не могли бути пов'язані з Вами особисто. У цьому випадку ми зможемо використовувати цю інформацію зі статистичною чи іншою метою без подальшого повідомлення, оскільки така інформація перестає бути персональними даними.</p>
              <p>5.2. Обробка персональних даних здійснюється Адміністрацією у статистичних чи інших дослідницьких цілях за умови обов'язкового знеособлення персональних даних.</p>
              <p>5.3. Адміністрація не здійснює обробку персональних даних, що стосуються расового чи етнічного походження, політичних, релігійних чи світоглядних переконань, членства в політичних партіях та професійних спілках, засудження до кримінального покарання, а також даних щодо здоров'я, статевого життя, біометричних та генетичних даних.</p>
              <p>5.4. Обробка персональних даних здійснюється за дотриманням умов, визначених законодавством України та GDPR.</p>
            </div>
            <h4>6. Терміни обробки персональних даних</h4>
            <div className='document_div'>
              <p>6.1. Терміни обробки персональних даних визначаються виходячи з цілей обробки, але не довше, ніж це визначено Законодавством.</p>
              <p>6.2. Персональні дані, термін обробки (зберігання) яких минув, мають бути знищені або знеособлені, якщо інше не передбачено Законом. Зберігання персональних даних здійснюється у формі, що дозволяє визначити суб'єкта персональних даних не довше, ніж цього вимагають цілі обробки персональних даних, якщо термін зберігання персональних даних не встановлений Законом. Оброблювані персональні дані підлягають знищенню або знеособленню після досягнення цілей обробки або у разі втрати необхідності досягнення цих цілей, якщо інше не передбачено Законом. Ми також повинні враховувати періоди, для яких нам, можливо, потрібно буде зберегти Ваші персональні дані для виконання наших юридичних зобов'язань перед Вами або контролюючими органами (відповідно до Регламенту ЄС 261/2004).</p>
              <p>6.3. Ми можемо з часом мінімізувати Ваші персональні дані, які ми використовуємо, або навіть можемо зробити Ваші дані анонімними, щоб вони не могли бути пов'язані з Вами особисто. У цьому випадку ми зможемо скористатися цією інформацією без подальшого інформування Вас.</p>
            </div>
            <h4>7. Коло осіб, допущених для обробки персональних даних з боку Адміністрації</h4>
            <div className='document_div'>
              <p>7.1. Для досягнення цілей цієї Політики до обробки персональних даних допущені лише ті співробітники Адміністрації, на яких покладено такий обов'язок відповідно до їхніх службових (трудових) обов'язків. Доступ інших працівників може бути наданий виключно у передбачених Законом випадках. Адміністрація гарантує від своїх співробітників дотримання конфіденційності та забезпечення безпеки персональних даних під час їх обробки.</p>
              <p>7.2. Адміністрація має право передати персональні дані третім особам у таких випадках:</p>
              <p>- суб'єкт персональних даних письмово висловив свою згоду на такі дії;</p>
              <p>- передача передбачена українським чи іншим відповідним законодавством у межах встановленої законодавством процедури. При цьому доступ до персональних даних третій особі не надається, якщо вказана особа відмовляється взяти на себе зобов'язання щодо забезпечення виконання вимог Закону або не може їх забезпечити.</p>
              <p>7.3. Адміністрація має право доручити обробку персональних даних третій особі за згодою суб'єкта персональних даних, якщо інше не передбачено законодавством України, на підставі укладеного з третьою особою договору, умовою якого є дотримання конфіденційності та нерозголошення персональних даних.</p>
              <p>7.4. Представники органів державної влади (у тому числі контролюючих, наглядових, правоохоронних та інших органів) отримують доступ до персональних даних, які опрацьовує Адміністрація, в обсязі та порядку, визначеному Законодавством.</p>
            </div>
            <h4>8. Реалізація захисту персональних даних</h4>
            <div className='document_div'>
              <p>8.1. Діяльність Адміністрації з обробки персональних даних в інформаційних системах нерозривно пов'язана із захистом Адміністрацією конфіденційності отриманої інформації, якщо це не суперечить чинному законодавству.</p>
              <p>8.2. Система захисту персональних даних включає організаційні та (або) технічні заходи, визначені з урахуванням актуальних загроз безпеці персональних даних та інформаційних технологій, що використовуються в інформаційних системах. Адміністрація здійснює оновлення цих заходів з появою нових технологій у разі потреби.</p>
              <p>8.3. Обмін персональними даними при їх обробці в інформаційних системах здійснюється каналами зв'язку, захищеними технічними засобами захисту інформації.</p>
              <p>8.4. Під час обробки персональних даних в інформаційних системах Адміністрацією забезпечуються:</p>
              <p>⦁ проведення заходів, спрямованих на запобігання несанкціонованому доступу до персональних даних та (або) передачу їх особам, які не мають права доступу до такої інформації;</p>
              <p>⦁ своєчасне виявлення фактів несанкціонованого доступу до персональних даних;</p>
              <p>⦁ недопущення впливу на технічні засоби автоматизованої обробки персональних даних, внаслідок якої може бути порушено їхнє функціонування;</p>
              <p>⦁ можливість негайного відновлення персональних даних, модифікованих та знищених внаслідок несанкціонованого доступу до них;</p>
              <p>⦁ постійний контроль захищенності персональних даних.</p>
              <p>8.5. Щодо персональної інформації зберігається конфіденційність, за винятком випадків, коли технологія онлайн-сервісу або налаштування програмного забезпечення, що використовується Користувачем, передбачають відкритий обмін інформацією з іншими Користувачами онлайн-сервісу або з будь-якими користувачами мережі Інтернет.</p>
              <p>8.6. Адміністрація реалізує такі вимоги законодавства України у сфері персональних даних:</p>
              <p>⦁ вимоги конфіденційності персональних даних;</p>
              <p>⦁ вимоги щодо забезпечення реалізації суб'єктом персональних даних своїх прав;</p>
              <p>⦁ вимоги щодо забезпечення точності персональних даних, а в необхідних випадках та актуальність щодо цілей обробки персональних даних (з вжиттям (забезпеченням вжиття) заходів щодо видалення або уточнення неповних чи неточних даних);</p>
              <p>⦁ вимоги до захисту персональних даних від неправомірного або випадкового доступу до них, знищення, спотворення, блокування, копіювання, надання, розповсюдження персональних даних, а також інших неправомірних дій щодо персональних даних;</p>
              <p>⦁ інші вимоги Закону.</p>
              <p>8.7. Відповідно до Закону Адміністрація самостійно визначає склад та перелік заходів, необхідних та достатніх для забезпечення виконання обов'язків, передбачених законодавством у сфері персональних даних від неавторизованої чи незаконної обробки та від ненавмисної втрати, знищення чи пошкодження.</p>
              <p>Адміністрація дотримується принципу мінімізації персональних даних. Ми обробляємо тільки ту інформацію про Вас, яка нам потрібна, або інформацію, яку Ви за своєю згодою надасте більше за необхідну для обробки. Крім цього, ми налаштували всі інтерфейси Онлайн-сервісу та програми для надання послуг так, щоб дотримувалася максимально можлива конфіденційність, налаштування якої можна відкоригувати на свій розсуд. При передачі персональних даних державним органам ми завжди використовуємо найбезпечніші та перевірені шляхи передачі таких даних.</p>
              <p>8.8. Cookies та інші технології відстеження.</p>
              <p>Cookies - це невеликі текстові файли, які зберігають сайти на комп'ютері або мобільних пристроях, коли Ви починаєте їх використовувати. Таким чином, сайт на якийсь час буде пам&rsquo;ятати Ваші переваги та дії, які Ви виконали, у тому числі для того, щоб Вам не потрібно було повторно вводити ці дані. Наші файли cookie самі по собі не ідентифікують окремого користувача, а ідентифікують лише комп'ютер або мобільний пристрій, яким Ви користуєтесь.</p>
              <p>Cookies та інші технології відстеження в нашому онлайн-сервісі можуть використовуватись різними способами, наприклад, з метою експлуатації онлайн-сервісу, аналізу відвідуваності або рекламних цілей. Cookies та інші технології відстеження ми використовуємо, зокрема, для підвищення якості та ефективності наших послуг.</p>
              <p>Для отримання додаткової інформації про те, що таке cookies, як вони працюють, як ними керувати або як їх видалити, перейдіть на www.allaboutcookies.org.</p>
              <p>Повідомляємо, що Ви можете в налаштуваннях деяких інтернет-браузерів налаштувати заборону cookies та інших технологій відстеження. При цьому Ви повинні розуміти, що якщо Ви відключите деякі файли cookies, функціональність онлайн-сервісу може бути обмежена і Ви не зможете використовувати всі переваги, а також можлива некоректна робота деяких сторінок.</p>
            </div>
            <h4>9. Права суб'єкта персональних даних</h4>
            <div className='document_div'>
              <p>9.1. Права суб'єктів персональних даних відповідно до законодавства України:</p>
              <p>9.1.1. Знати про джерела збору, місцезнаходження своїх персональних даних, мету їх обробки, місцезнаходження розпорядника персональних даних або дати відповідне доручення щодо отримання цієї інформації уповноваженим особам, крім випадків, встановлених Законом.</p>
              <p>9.1.2. Отримувати інформацію про умови надання доступу до персональних даних, включаючи інформацію про третіх осіб, яким передаються його персональні дані.</p>
              <p>9.1.3. Доступ до своїх персональних даних.</p>
              <p>9.1.4. Отримувати не пізніше, ніж за тридцять календарних днів з дня надходження запиту, крім випадків, передбачених Законом, відповідь про те, чи обробляються його персональні дані, та які саме.</p>
              <p>9.1.5. Пред'являти вмотивовану вимогу до Адміністрації про заперечення щодо обробки своїх персональних даних.</p>
              <p>9.1.6. Подавати мотивовану вимогу про зміну або знищення своїх персональних даних, якщо дані обробляються незаконно або є недостовірними.</p>
              <p>9.1.7. На захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв'язку з умисним приховуванням, ненаданням або несвоєчасним їх наданням, а також на захист від надання відомостей, які є недостовірними або ганьблять гідність та ділову репутацію фізичної особи.</p>
              <p>9.1.8. Звертатись зі скаргами на обробку своїх персональних даних до Адміністрації до Уповноваженого Верховної Ради України з прав людини або до суду.</p>
              <p>9.1.9. Застосовувати засоби правового захисту у разі порушення законодавства щодо захисту персональних даних.</p>
              <p>9.1.10. Вносити застереження щодо обмеження права на обробку своїх персональних даних при наданні згоди.</p>
              <p>9.1.11. Відкликати згоду на обробку персональних даних.</p>
              <p>9.1.12. Ознайомитись із механізмом автоматичної обробки персональних даних.</p>
              <p>9.1.13. На захист від автоматизованого рішення, яке має для нього правові наслідки.</p>
              <p>9.1.14. Адміністрація має право доручити обробку персональних даних третій особі за згодою суб'єкта персональних даних, якщо інше не передбачено законодавством України, на підставі укладеного з третьою особою договору, умовою якого є дотримання конфіденційності та нерозголошення персональних даних.</p>
              <p>9.1.15. Представники органів державної влади (у тому числі контролюючих, наглядових, правоохоронних та інших органів) отримують доступ до персональних даних, що обробляються в Адміністрації, в обсязі та порядку, визначеному законодавством України.</p>
              <p>9.2. Інші права суб'єктів персональних даних відповідно до GDPR:</p>
              <p>Окрім українського законодавства щодо захисту персональних даних, Адміністрація уважно ставиться до забезпечення Ваших прав, встановлених GDPR.</p>
              <p>9.2.1. Право на інформацію.</p>
              <p>Ми готові надавати суб'єктам даних інформацію про те, які їх персональні дані ми обробляємо.</p>
              <p>Якщо Ви матимете бажання дізнатися, які Ваші персональні дані ми обробляємо, Ви зможете зробити запит на цю інформацію у будь-який час, у тому числі, шляхом звернення до Адміністрації. Перелік даних, які ми повинні Вам надати, Ви можете знайти у статтях 13 та 14 GDPR. При цьому, при зверненні Ви повинні повідомити свої конкретні вимоги, щоб ми могли на законних підставах розглянути Ваш запит і дати відповідь.</p>
              <p>Зверніть увагу, що у разі, якщо ми не зможемо засвідчити Вашу особу шляхом обміну електронними повідомленнями або під час Вашого звернення по телефону, або у разі обґрунтованих сумнівів щодо Вашої особи, ми можемо попросити Вас надати документ, що засвідчує особу, у тому числі шляхом особистої явки на адресу місця знаходження Адміністрації. Тільки таким чином ми зможемо уникнути розголосу Ваших персональних даних особі, яка може видавати себе за Вас.</p>
              <p>Ми опрацюємо запити у найкоротші терміни, але водночас просимо пам'ятати, що надання повної та законної відповіді щодо персональних даних &ndash; це складний процес, який може тривати до місяця.</p>
              <p>9.2.2. Право на виправлення даних у Вас.</p>
              <p>Якщо Ви виявите, що деякі персональні дані, які ми обробляємо про Вас, є неправильними або застарілими, будь ласка, повідомте нам про це. У цьому випадку ми можемо попросити Вас надати документ, що засвідчує особу, в тому числі шляхом особистої явки на адресу місця знаходження Адміністрації.</p>
              <p>Якщо Ви хочете виправити персональні дані, які обробляються нами, Ви можете провести коригування самостійно, виконавши вхід до свого особистого кабінету онлайн-сервісу або зв'язавшись з Адміністрацією.</p>
              <p>У деяких випадках ми не зможемо змінити Ваші персональні дані. Зокрема, таким випадком можливо, коли Ваші персональні дані вже були використані у процесі виконання договору та/або вони містяться у податковому документі, який був оформлений відповідно до Податкового законодавства.</p>
              <p>9.2.3. Відкликання згоди на обробку персональних даних та право на забуття</p>
              <p>Якщо Адміністрація обробляє Ваші персональні дані на підставі згоди на обробку персональних даних (зокрема, з метою здійснення маркетингових/рекламних розсилок), подальшу обробку можна у будь-який час припинити. Достатньо відкликати згоду на таку обробку.</p>
              <p>Ви також можете використовувати своє право на забуття. У випадках, передбачених у ст.17 GDPR, Адміністрація знищить Ваші персональні дані, які опрацьовує, за винятком персональних даних, які ми зобов'язані будемо зберегти відповідно до вимог законодавства.</p>
              <p>Так само і в цьому випадку, з метою безпеки, Адміністрація може попросити Вас надати документ, що засвідчує особу, зокрема безпосередньо за адресою місця знаходження Адміністрації.</p>
            </div>
            <h4>10. Місце зберігання персональних даних</h4>
            <div className='document_div'>
              <p>Адміністрація має велику базу персональних даних. Для забезпечення їхньої безпеки ми використовуємо хмарні послуги компанії DigitalOcean, LLC. Дані знаходяться в центрах обробки даних у місті Франкфурт-на-Майні, Німеччина.</p>
            </div>
            <h4>11. Изменение политики конфиденциальности</h4>
            <div className='document_div'>
              <p>11.1. Ця Політика може бути змінена або припинена Адміністрацією в односторонньому порядку без попереднього повідомлення Користувачів, у тому числі, якщо цього вимагає законодавство. Нова редакція Політики набирає чинності з її розміщення в онлайн-сервісі, якщо інше не передбачено новою редакцією Політики. Тому ми просимо Вас відвідувати онлайн-сервіс https://besure.stimul.ua, щоб переконатися в тому, що Ви маєте актуальну інформацію.</p>

              <h4>12. До кого Ви можете звернутися для захисту Ваших персональних даних</h4>

              <p>12.1. Якщо у Вас виникнуть питання, зауваження, скарги чи побажання щодо захисту та обробки персональних даних, Ви можете звертатися до Директора ФОП &ldquo;Алексанкін І.С.&rdquo; Алексанкіну Іллі Сергійовичу, E-mail: info@stimul.ua , поштова адреса: 50000, м. Кривий Ріг, пр. Поштовий, 52,405.</p>
              <p>Обов'язково вказуйте у всій кореспонденції ім'я, прізвище, адресу електронної пошти, а також докладні запитання, зауваження, скарги або вимоги.</p>
              <p>12.2. Адміністративний орган захисту персональних даних в Україні - це Управління з питань захисту персональних даних Секретаріату Уповноваженого Верховної Ради України з прав людини. Ви можете звертатися до нього зі скаргами або пропозиціями, якщо вважаєте, що порушено Ваші права у зв'язку з обробкою персональних даних.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}





