import React from 'react'
import Chart from '../../components/Result/Chart/Chart';
// import SaveYourself from '../../components/SaveYourself/SaveYourself';
import FAQ from './../../components/FAQ/FAQ';
import HowWorks from './../../components/HowWorks/HowWorks';
import ItWork from './../../components/It_works/It_work';
import WelcomeBlock from './../../components/welcome-block/WelcomeBlock.js'
import { useTranslation } from 'react-i18next';

const Home = ({ setShowModal }) => {
    let dataHome = [
        { Question: `Q 1`, Beats: 44 },
        { Question: `Q 2`, Beats: 77 },
        { Question: `Q 3`, Beats: 28 },
        { Question: `Q 4`, Beats: 33 },
        { Question: `Q 5`, Beats: 24 },
        { Question: `Q 6`, Beats: 97 },
        { Question: `Q 7`, Beats: 47 },
    ]
    const {t,i18n} = useTranslation();
    return (
        <>
            <WelcomeBlock setShowModal={setShowModal} ibg='ibg' ></WelcomeBlock>
            <HowWorks setShowModal={setShowModal} />
            <ItWork setShowModal={setShowModal} />
            <Chart height={400} titleAxis={''} data={dataHome} description={t("description_home")} />
            <FAQ setShowModal={setShowModal} />
        </>
    )
}

export default Home