import React, { useState } from 'react'
import './Testing.scss'
import line from './../../assets/testing_process/line.svg'
import Button from '../buttons/Button'
import illustration from './../../assets/testing_process/illustration.png'
import mob from './../../assets/testing_process/mob.png'
import icon_1 from './../../assets/testing_process/Iconly-Light-Document.svg'
import icon_2 from './../../assets/testing_process/Iconly-Light-Wallet.svg'
import icon_3 from './../../assets/testing_process/Iconly-Light-Tick Square.svg'
import icon_4 from './../../assets/testing_process/Iconly-Light-Message.svg'
import { useTranslation } from 'react-i18next';
const ItWork = ({ setShowModal }) => {
    // const [showModal, setShowModal] = useState(false);
    const {t,i18n} = useTranslation();
    const downloadPDFFile = () => {
        // using Java Script method to get PDF file
        fetch('Test_report.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'Test_report.pdf';
                alink.click();
            })
        })
    }
    return (
        <section id="ItWork" className="block_testing">
            <div className="container">
                <div className="block_testing__header">
                    <h2 className="block_testing__header__title">{t('title')}</h2>
                    <img src={line} alt="line" className="block_testing__header__img" />
                </div>
                <div className="block_testing__main">
                    <div className="block_testing__main__info">
                        <div className="block_testing__main__info__items">
                            <div className="block_testing__main__info__item after-1">
                                <img src={icon_2} alt="icon_1" className="block_testing__main__info__item__image" />
                                <div className="block_testing__main__info__item__text">
                                    <h4 className="block_testing__main__info__item__text__title">{t('title_calibration')}</h4>
                                    <p className="block_testing__main__info__item__text__description">
                                    {t('description_calibration')}
                                    </p>

                                </div>
                            </div>
                            <div className="block_testing__main__info__item after-2">
                                <img src={icon_1} alt="icon_1" className="block_testing__main__info__item__image" />
                                <div className="block_testing__main__info__item__text">
                                    <h4 className="block_testing__main__info__item__text__title">{t('title_make')}</h4>
                                    <p className="block_testing__main__info__item__text__description">
                                    {t('description_make')}
                                    </p>

                                </div>
                            </div>
                            <div className="block_testing__main__info__item after-3">
                                <img src={icon_3} alt="icon_1" className="block_testing__main__info__item__image" />
                                <div className="block_testing__main__info__item__text">
                                    <h4 className="block_testing__main__info__item__text__title">{t('title_pay')}</h4>
                                    <p className="block_testing__main__info__item__text__description">
                                    {t('description_pay')} <span style={{ textDecoration: 'line-through', fontWeight: 'bold' }}>{t('price_crossed')}</span> <span style={{ color: '#AD84CF', fontWeight: 'bold' }}> {t('price_real')} </span>{t('description_payAll')}
                                    </p>
                                </div>
                            </div>
                            <div className="block_testing__main__info__item after-4">
                                <img src={icon_4} alt="icon_1" className="block_testing__main__info__item__image" />
                                <div className="block_testing__main__info__item__text">
                                    <h4 className="block_testing__main__info__item__text__title">{t('title_mail')}</h4>
                                    <p className="block_testing__main__info__item__text__description">
                                    {t('description_email')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="block_testing__main__info__buttons">
                            <Button style={{ zIndex: '9' }} id='Popup_third_screen' onClick={() => setShowModal(true)}>{t('button_sell')}</Button>
                            <Button style={{ zIndex: '9', background: '#AC82CD' }} onClick={downloadPDFFile} id='downloadPDF' >{t('button_pdf')}</Button>

                        </div>
                    </div>
                    <div className="block_testing__main__picture">
                        <img src={illustration} alt="illustration" className="block_testing__main__picture__image" />
                        <img src={mob} alt="illustration" className="block_testing__main__picture__image-mob" />
                    </div>

                </div>
            </div>
        </section>
    )
}

export default ItWork