import React, { useEffect, useState, useRef } from "react";
import "./TestResult.scss";
import line from "./../../../assets/Howitworks/line.svg";
import { useTranslation } from 'react-i18next';
import Button from "../../buttons/Button";
import axios from 'axios';
import lottie from "lottie-web";
import load from "./../../../assets/loading_white.json";
import Chart from "../Chart/Chart";


const TestResult = ({ setShowModal }) => {
    const { t, i18n } = useTranslation();
    const [loading, setloading] = useState(true);
    const id = localStorage.getItem('firstID');
    const [list, setList] = useState([]);
    // const [numbResult, setnumbResult] = useState(0);
    const initialState = [];
    // const [arrayBPM, setarrayBPM] = useState(initialState);
    const [FinisharrayBPM, setFinisharrayBPM] = useState(initialState);
    const componentRef = useRef();
    let enpoints = `${process.env.REACT_APP_API}/answers/${id}`;
    let elem = 0;
    let maxElem = 0;


    const getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min)) + min;
    }


    useEffect(() => {

        const fetchdata = () => {
            axios.get(enpoints)
                .then(response => {
                    for (let i = 0;i < response.data.length;i++) {
                        let beaten = i + 1
                        setList((arr) => [
                            ...arr,
                            {
                                id: `${i + 1}`,
                                question: response.data[i].title,
                                answer: response.data[i].answer,
                                reaction: response.data[i].reaction,
                            },
                        ]);
                        const findBPMNeed = (data, id) => {
                            if (data === t('yes') && id === 1) {
                                return getRandomInt(20, 39)
                            }
                            if (data === t('no') && id === 1) {
                                return getRandomInt(75, 84)
                            }
                            if (data === t('yes') && id === 2) {
                                return getRandomInt(92, 100)
                            }
                            if (data === t('no') && id === 2) {
                                return getRandomInt(40, 60)
                            }
                            if (data === t('yes') && id === 3) {
                                return getRandomInt(40, 60)
                            }
                            if (data === t('no') && id === 3) {
                                return getRandomInt(75, 89)
                            }
                            if (data === t('yes') && id === 4) {
                                return getRandomInt(37, 53)
                            }
                            if (data === t('no') && id === 4) {
                                return getRandomInt(80, 94)
                            }
                            if (data === t('yes') && id === 5) {
                                return getRandomInt(36, 68)
                            }
                            if (data === t('no') && id === 5) {
                                return getRandomInt(75, 92)
                            }
                            if (data === t('yes') && id === 6) {
                                return getRandomInt(92, 100)
                            }
                            if (data === t('no') && id === 6) {
                                return getRandomInt(40, 60)
                            }
                        }

                        let obj = {
                            Question: `Q ${i + 1}`, Beats: findBPMNeed(response.data[i].answer, beaten)
                        }
                        setFinisharrayBPM(current => [...current, obj]);

                        if (response.data[i].type === '' || response.data[i].type === ' ' || response.data[i].type === '  ') {
                            elem = elem + response.data[i].points;
                        } else {
                            maxElem = Math.max(maxElem, response.data[i].points);

                        }
                        // setnumbResult(Math.max(elem, maxElem));
                    }

                }).catch(error => console.log(error))
        }
        lottie.loadAnimation({
            container: document.querySelector("#loading-anim"),
            animationData: load,
            renderer: "svg", // "canvas", "html"
            loop: true, // boolean
            autoplay: true, // boolean
        });
        setTimeout(() => {
            setloading(false);
        }, 5000);
        fetchdata();


    }, []);

    // useEffect(() => {
    //     let result = arrayBPM.map(a => a.Beats);
    //     let maxData = Math.max(...result);
    //     let mindata = Math.min(...result);
    //     let BPMrange = maxData - mindata;
    //     let hundredTick = 100 / BPMrange;
    //     for (let [index, value] of result.entries()) {
    //         let obj = { Question: `Q ${index + 1}`, Beats: Math.round((value - mindata) * hundredTick) }
    //         setFinisharrayBPM(current => [...current, obj]);

    //     }
    // }, [arrayBPM]);
    const colNames = [t('result_table_names'), t('result_table_question'), t('result_table_answer'), t('result_table_reaction')];

    const Addimage = (item, index2) => {

        if (typeof item !== 'string') {
            return
        }
        if (item === 'suffier') {
            return
        }
        if (item.toString().substring(0, 3) === t('yes')) {
            return (
                <>
                    <strong>{item.toString().substring(0, 3)}</strong>
                </>
            )
        }
        if (item.toString().substring(0, 2) === t('no')) {
            return item.toString().substring(0, 2);
        }
        if (item.toString().substring(0, 4) === "data") {
            return (
                <>
                    <img
                        cross-origin="anonymous"
                        // ref={(el) => (imgRef.current[index2] = el)}
                        id={index2}
                        className="image__table"
                        src={item}
                        alt={t('photowithreaction')}
                    />
                    <canvas
                        // ref={(el) => (canvasRef.current[index2] = el)}
                        id={index2}
                        className="canvas_table"
                    ></canvas>
                    <p className="emotion_detection">{t('premium')}</p>
                </>
            );
        } else {
            return item;
        }
    };
    return (
        <>

            {loading && <div className="loading">
                <div id="loading-anim" className="img_loading" />
            </div>}

            <div ref={componentRef}>
                {/* {numbResult > 0 && <ResultTable list={list} numbResult={numbResult} />} */}
                <section style={{ opacity: loading ? '0' : '1' }} className="block__Table">
                    <div className="container">
                        {FinisharrayBPM.length !== 0 && <Chart dataforpoint={list} height={500} data={FinisharrayBPM} />}
                        <div className="block__Table__Head">
                            <h2 className="block__Table__title">{t('result_title_questions')}</h2>
                            <img src={line} alt="line" className="block__Table__line" />
                        </div>
                        {list.length > 0 && (
                            <table className="Board" cellSpacing="0">
                                <thead className="Board__head">
                                    <tr className="Board__line">
                                        {colNames.map((headerItem, index) => (
                                            <th className="Board__cell" key={index}>
                                                {headerItem.toUpperCase()}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="Body">
                                    {Object.values(list).map((obj, index) => (
                                        <tr className="Body__line" key={index}>
                                            {Object.values(obj).map((value, index2) => (
                                                <td className="Body__cell" key={index2}>
                                                    {Addimage(value, index2)}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}


                        <div className="block__Table__buttons">

                            <Button onClick={() => setShowModal(true)}>{t('test_your_partner')}</Button>

                        </div>


                    </div>
                </section>
            </div>
        </>
    );
};

export default TestResult;
