import React, { useEffect, useState, useRef } from "react";
import "./Table.scss";
import line from "./../../../assets/Howitworks/line.svg";
import veget from "./../../../assets/EndResult/vegetSystem.png";
import Emotion_flitr from "./../../../assets/EndResult/Emotion_flitr.png";
import * as faceapi from "face-api.js";
import Button, { PayPal } from "../../buttons/Button";
import axios from 'axios';
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import lottie from "lottie-web";
import load from "./../../../assets/loading_white.json";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../../popup/Popup";
import { useTranslation } from 'react-i18next';
import InputComponent from "../../InputElement/inputelement";

function generateShortId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0;i < 9;i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
    return result;
}

const COLORS = ["#707070", "#ffe599", "#6e339c", "#ea4335", "#fc8403", "#0fd153", "#0fd1ce"];
// "neutral", "happy", "sad", "angry", "fearful", "disgusted", "surprised"

const AppPieChart = (
    { data,
        textData }
) => {

    const { t, i18n } = useTranslation();
    return (
        <div className='Bublik' style={{ width: '100%' }}>
            <ResponsiveContainer>
                <PieChart>
                    <text x={'50%'} y={'50%'} className='bublik_text' textAnchor="middle" dominantBaseline="middle">
                        {textData}
                    </text>
                    <Pie
                        data={data}
                        fill="#8884d8"
                        innerRadius={'43%'}
                        outerRadius={'65%'}
                        paddingAngle={1}
                        dataKey="value"
                    >
                        {data.length === 7 ? data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        )) : <Cell fill={'#707070'} />}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}

const TableId = () => {
    const { t, i18n } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const TableEmotions = useRef()
    const [loading, setloading] = useState([]);
    const { id } = useParams();
    const [list, setList] = useState([]);
    const [Expressions, setExpressions] = useState([]);
    const componentRef = useRef();
    const [inputValue, setInputValue] = useState('');
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };
    let enpoints = `${process.env.REACT_APP_API}/answers/${id}`;
    const headers = {
        'Content-Type': 'application/json'
    }

    const onSubmit = async () => {
        // await axios.post(`${process.env.REACT_APP_API}/payment/${id}`).then((response) => {
        //     window.location.href = response.data === 100 ? `${process.env.REACT_APP_SITE}/result/${id}` : response.data.checkout_url;
        // });

        const formData = {
            merchantAccount: "psy_lica_center_com_ua",
            apiVersion: "2",
            merchantDomainName: "https://besure.stimul.ua",
            orderReference: generateShortId(),
            orderDate: Date.now(),
            amount: 699,
            serviceUrl: "https://nannyapi.stimul.ua/wayforpay",
            returnUrl: `https://besure.stimul.ua/result/${id}`,
            currency: "UAH",
            productName: 'Тестування на зраду',
            productCount: 1,
            productPrice: 699,
            merchantSignature: "",
        };

        // Генерация подписи
        const generateSignature = () => {
            const signatureString = `${formData.merchantAccount};${formData.merchantDomainName};${formData.orderReference};${formData.orderDate};${formData.amount};${formData.currency};${formData.productName};${formData.productCount};${formData.productPrice}`;
            return CryptoJS.HmacMD5(signatureString, "d148813f16d2ec8958ca08df7e1066086163e221").toString(CryptoJS.enc.Hex);
        };

        formData.merchantSignature = generateSignature();

        // Создаем и отправляем форму программно
        const wayforpayForm = document.createElement('form');
        wayforpayForm.action = "https://secure.wayforpay.com/pay";
        wayforpayForm.method = "POST";

        for (const key in formData) {
            if (formData.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = formData[key];
                wayforpayForm.appendChild(hiddenField);
            }
        }

        document.body.appendChild(wayforpayForm);
        wayforpayForm.submit();
    }

    useEffect(() => {
        const fetchdata = () => {
            axios.get(enpoints)

                .then(response => {
                    for (let i = 0;i < response.data.length;i++) {
                        setList((arr) => [
                            ...arr,
                            {
                                id: `${i + 1}`,
                                question: JSON.parse(response.data[i].title),
                                answer: JSON.parse(response.data[i].answer),
                                reaction: response.data[i].reaction,
                            },
                        ]);
                    }

                }).catch(error => console.log(error))
        }
        lottie.loadAnimation({
            container: document.querySelector("#loading-anim"),
            animationData: load,
            renderer: "svg", // "canvas", "html"
            loop: true, // boolean
            autoplay: true, // boolean
        });
        setTimeout(() => {
            setloading(false);
        }, 10000);
        fetchdata();


    }, []);

    useEffect(() => {

        const handleImage = async () => {
            const images = document.querySelectorAll('.image__table');

            const canvases = document.querySelectorAll('.canvas_table');
            for (let image = 0;image < images.length;image++) {

                const useTinyModel = true
                const detections = await faceapi
                    .detectSingleFace(images[image], new faceapi.TinyFaceDetectorOptions())
                    .withFaceLandmarks(useTinyModel)
                    .withFaceExpressions();
                canvases[image].innerHtml = faceapi.createCanvasFromMedia(images[image]);
                if (detections?.expressions) {
                    let arr = Object.values(detections.expressions);
                    let keys = Object.keys(detections.expressions);
                    let objectd = [];

                    for (var i = 0;i < keys.length;i++) {

                        let keyValue = parseFloat(arr[i]).toFixed(3);


                        objectd.push({ 'name': `${keys[i]}`, 'value': Number(keyValue) })
                    }

                    setExpressions(prevState => [...prevState, objectd]);

                    let max = Math.max(...arr);
                    for (let prop in detections.expressions) {

                        if (detections.expressions[prop] === max) {
                            if (detections.expressions[prop] > 0.50) {
                                // emotions[image].innerText = prop;
                            } else {

                                // emotions[image].innerText = 'Лицо скрыто';
                            }


                        } else if (detections.expressions[prop] === undefined) {
                            // emotions[image].innerText = 'Лицо отсутствует';
                            break
                        }
                    }
                } else {
                    let objectd = [
                        { 'name': t('unknown'), 'value': 1 },
                        { 'name': `neutral`, 'value': 0 },
                        { 'name': `neutral`, 'value': 0 },
                        { 'name': `neutral`, 'value': 0 },
                        { 'name': `neutral`, 'value': 0 },
                        { 'name': `neutral`, 'value': 0 },
                        { 'name': `neutral`, 'value': 0 }
                    ]
                    setExpressions(prevState => [...prevState, objectd]);
                    continue
                }
                faceapi.matchDimensions(canvases[image], {
                    width: images[image].scrollWidth,
                    height: images[image].scrollHeight
                })

                const resized = faceapi.resizeResults(detections, {
                    width: images[image].scrollWidth,
                    height: images[image].scrollHeight
                })
                faceapi.draw.drawDetections(canvases[image], resized)
                faceapi.draw.drawFaceLandmarks(canvases[image], resized)

            }
        };

        let loadModels = async () => {

            Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
                faceapi.nets.faceLandmark68TinyNet.loadFromUri("/models"),
                faceapi.nets.faceExpressionNet.loadFromUri("/models"),
            ])
            handleImage().catch((e) => console.log(e));
        };
        (loadModels());
    }, [loading]);

    localStorage.setItem('id', id);

    const colNames = [t('result_table_names'), t('result_table_question'), t('result_table_answer'), t('result_table_reaction')];

    const Addimage = (item, index, index2) => {
        if (typeof item === 'object') {
            if (item[i18n.language].substring(0, t('yes').length) === t('yes')) {
                return (
                    <>
                        {Expressions.length === list.length ? <AppPieChart textData={t('result_table_answer')} data={Expressions[parseInt(index[0]) - 1]} /> : <></>}
                    </>
                )
            }
            if (item[i18n.language].substring(0, t('no').length) === t('no')) {
                return (
                    <>
                        {Expressions.length === list.length ? <AppPieChart textData={t('result_table_answer')} data={Expressions[parseInt(index[0]) - 1]} /> : <></>}
                    </>
                )
            }
            if (item[i18n.language]) {
                return (
                    <>
                        <p>{JSON.stringify(item[i18n.language])}</p>
                    </>

                )
            }
        }
        if (item === 'suffier') {
            return
        }

        if (item.toString().substring(0, 4) === "data") {
            return (
                <>
                    <div className="image__canvas_elements">
                        <img
                            cross-origin="anonymous"
                            // ref={(el) => (imgRef.current[index2] = el)}
                            id={`img${parseInt(index[0])}`}
                            className="image__table"
                            src={item}
                            alt={t('photowithreaction')}
                        />
                        <canvas
                            // ref={(el) => (canvasRef.current[index2] = el)}
                            id={`canvas${parseInt(index[0])}`}
                            className="canvas_table"
                        ></canvas>
                    </div>
                    <p style={{ backgroundColor: 'currentColor' }} className="emotion_detection"><Button style={{ padding: '5px 15px', height: '35px', marginBottom: '0px', fontSize: '0.75em' }} onClick={() => setShowModal(true)}>{t('KnowReaction')}</Button></p>

                </>
            );
        } else {
            return item;
        }
    };
    return (
        <>
            {loading && <div className="loading">
                <div id="loading-anim" className="img_loading" />
            </div>}
            <div ref={componentRef}>
                <section style={{ opacity: loading ? '0' : '1' }} className="block__Table">
                    <div className="container">
                        <div className="block__itWorks__header">

                            <img src={line} className='block__itWorks__header__img' alt="line" />
                            <h2 className="block__itWorks__header__title">{t('result_title_system')}</h2>

                        </div>
                        <div className='centerImageElement'>
                            <img src={veget} alt="line" className="block__Table__centerImage" />
                        </div>
                        <div className="block__Table__buttons">
                            <Button onClick={() => setShowModal(true)}>{t('getData')}</Button>
                        </div>
                        <div className="block__Table__Head">
                            <h2 className="block__Table__title">{t('result_title_questions')}</h2>
                            <img src={line} alt="line" className="block__Table__line" />
                        </div>
                        {list.length > 0 && (
                            <table className="Board" cellSpacing="0">
                                <thead className="Board__head">
                                    <tr className="Board__line">
                                        {colNames.map((headerItem, index) => (
                                            <th className="Board__cell" key={index}>
                                                {headerItem.toUpperCase()}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="Body">
                                    {Object.values(list).map((obj, index) => (
                                        <tr className="Body__line" key={index}>
                                            {Object.values(obj).map((value, index2, indexed = index) => (
                                                <td className="Body__cell" key={index2}>
                                                    {Addimage(value, indexed, index2)}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        <div id="emotions" ref={TableEmotions} style={{ paddingTop: '30px' }} className="block__Table__Head">
                            <h2 className="block__Table__title">{t('result_title_emotions')}</h2>
                            <img src={line} alt="line" className="block__Table__line" />
                        </div>
                        <div className='centerImageElement'>
                            <img src={Emotion_flitr} alt="line" className="block__Table__centerImage2" />
                        </div>
                        <div className="block__Table__buttons">
                            <Button className='sad' onClick={() => setShowModal(true)}>{t('getFullData')}</Button>
                        </div>


                    </div>
                </section>
            </div>
            {showModal ? <Modal
                show={showModal}
                setShow={setShowModal}
            >

                <ModalHeader>
                    <h4 style={{ marginBottom: "20px" }}>{t('aretherefraud')}</h4>
                    <h5 style={{ marginBottom: "20px" }}>{t('reactionFullData')}</h5>
                    <h6>{t('now')}</h6>
                </ModalHeader>
                <ModalBody>
                    {/* <PayPal id={id}/> */}
                    {/* <InputComponent
                        placeholder={t('promocode')}
                        inputValue={inputValue}
                        handleInputChange={handleInputChange}
                    /> */}
                    <Button style={{ margin: '0 auto' }} onClick={() => onSubmit()}>{t('knowtruth')}</Button>
                    {/* <a href="https://secure.wayforpay.com/button/be2aa31a3c174" className="wayforpay"> <span className="wayforpaySpan" >{t('knowtruth')}</span></a> */}
                    {/* <FormEnd thissetStep={setStep} seturlParam={seturlParam} /> */}
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Modal>
                : null}
        </>
    );
};

export default TableId;
