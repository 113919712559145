
import React, { useState, useEffect } from "react";
import "./Diagnose.scss";
import { ModalNoGo, ModalBody } from "../../components/popup/Popup";
import Questions from "./questions";
import Button from "../../components/buttons/Button";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

const Diagnose = () => {
  const [loading, setloading] = useState(true);
  const [uuidFor, setuuidFor] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [activeQuestion, setActiveQuestion] = useState(0);
  // const [allanswers, setAllanswers] = useState([]);
  const [step, setStep] = useState(2);
  const [Quiz, setQuiz] = useState([]);
  let demotext = 'Демонстраційний тест';
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setuuidFor(uuidFor => [...uuidFor, uuidv4().replace(/-/gi, '')]);
    setTimeout(() => {
      setloading(false);
    }, 15000);
    let enpoints = `${process.env.REACT_APP_API}/questions/66fba18493f73fa1de496cc3`;
    console.log(enpoints);
    axios.get(enpoints)
      .then(response => {
        console.log(response.data);
        setQuiz(response.data['boyfrendRU']);

      })
      .catch(error => console.log(error))
  }, [])
  const goDemo = () => {
    window.location.href = `${process.env.REACT_APP_SITE}/demo`;
  }
  return (

    <section className="page__diagnose">

      <div className="container">

        {step === 2 && Quiz.length !== 0 && <Questions AboveQuestionText={demotext}
          uuidFor={uuidFor[0]}
          loading={loading}
          data={Quiz}
          // onAnswerUpdate={setAllanswers}
          numberOfQuestions={Quiz.length}
          activeQuestion={activeQuestion}
          onSetActiveQuestion={setActiveQuestion}
          onSetStep={setStep}
        ></Questions>}
        {step === 3 && <Questions
          uuidFor={uuidFor[0]}
          data={Quiz}
          // onAnswerUpdate={setAllanswers}
          numberOfQuestions={Quiz.length}
          activeQuestion={activeQuestion}
          onSetActiveQuestion={setActiveQuestion}
          onSetStep={setStep}
        ></Questions> && <ModalNoGo
          show={showModal}
          setShow={setShowModal}
        >
            <ModalBody>
              <Button onClick={goDemo} style={{ margin: "0 auto" }}>{t('get_finalResults')}</Button>
            </ModalBody>
          </ModalNoGo>
        }

      </div>

    </section>
  );

};

export default Diagnose;
