import React from 'react'
import { useTranslation } from 'react-i18next';

const ChangeLanguage = () => {
    const {t,i18n} = useTranslation();
    
    const changeLanguage = (language)=>{
      localStorage.setItem('language',language);
        i18n.changeLanguage(language);
    };
  return (
    <>
    
    {i18n.language ==='uk' ? <>
    <a onClick={()=>changeLanguage('ru')}>RU</a>
    <a onClick={()=>changeLanguage('eng')}>ENG</a>
    </> 
    : i18n.language ==='ru' ?
   <>
    <a onClick={()=>changeLanguage('uk')}>UA</a>
    <a onClick={()=>changeLanguage('eng')}>ENG</a>
    </> 
     : 
   <>
    <a onClick={()=>changeLanguage('uk')}>UK</a>
    <a onClick={()=>changeLanguage('ru')}>RU</a>
    </> 
    }
    </>
  )
};

export default ChangeLanguage