import React, { useEffect, useState } from "react";
import RadialSeparators from '../../details/RadialSeparators/RadialSeparators';
import {
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import './Result.scss';
import { useTranslation } from 'react-i18next';
export const ResultTable = ({ numbResult, list }) => {
    const {t,i18n} = useTranslation();
    const [resultTitle, setresultTitle] = useState('');
    const [description, setdescription] = useState(0);

    useEffect(() => {
        const startnumb = () => {
            if (numbResult < 25) {
                setresultTitle(t('probability1'));
                setdescription(1);

            } else if (numbResult < 50) {
                setresultTitle(t('probability2'));
                setdescription(2);
            } else if (numbResult <= 75) {
                setresultTitle(t('probability3'));
                setdescription(3);
            } else {
                setresultTitle(t('probability4'));
                setdescription(4);
            }
        }
        startnumb();
    }, [list,i18n.language]);



    return (
        <section className="ResultTable">
            <div className="container">
                <div className="ResultTable__main">
                    <h1 className="ResultTable__title">{resultTitle}</h1>
                    <div className="ResultTable__body">
                        <p className="ResultTable__text">{t('probability')}</p>
                        <div className="ResultTable__animation">
                            <div className="ResultTable__loader">
                                <div className="ResultTable__loader2"></div>
                                <CircularProgressbarWithChildren
                                    value={numbResult}
                                    text={`${numbResult}%`}
                                    strokeWidth={15}
                                    styles={buildStyles({
                                        pathColor: "#1AEFA4",
                                        trailColor: "transparent",
                                        strokeLinecap: "butt"
                                    })}
                                >
                                    <RadialSeparators
                                        count={100}
                                        style={{
                                            background: "#D1A4F5",
                                            width: "2px",
                                            // This needs to be equal to props.strokeWidth
                                            height: `${15}%`
                                        }}
                                    />
                                </CircularProgressbarWithChildren>


                            </div>
                        </div>
                        {description === 1 &&
                            <>
                                <p style={{ textAlign: 'left', fontWeight: 'bold' }} className="ResultTable__textbelow"> {t('var1_description_text1')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow" >{t('var1_description_text2')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var1_description_text3')}</p>

                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var1_description_text4')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var1_description_text5')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var1_description_text6')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var1_description_text7')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var1_description_text8')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var1_description_text9')}</p>
                            </>
                        }
                        {description === 2 &&
                            <>
                                <p style={{ textAlign: 'left', fontWeight: 'bold' }} className="ResultTable__textbelow">{t('var2_description_text1')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow" >{t('var2_description_text2')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var2_description_text3')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var2_description_text4')}</p>
                            </>
                        }
                        {description === 3 &&
                            <>
                                <p style={{ textAlign: 'left', fontWeight: 'bold' }} className="ResultTable__textbelow">{t('var3_description_text1')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow" >{t('var3_description_text2')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var3_description_text3')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var3_description_text4')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var3_description_text5')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var3_description_text6')} </p>
                            </>
                        }
                        {description === 4 &&
                            <>
                                <p style={{ textAlign: 'left', fontWeight: 'bold' }} className="ResultTable__textbelow">{t('var4_description_text1')}  </p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow" >{t('var4_description_text2')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var4_description_text3')} </p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var4_description_text4')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var4_description_text5')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var4_description_text6')} </p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var4_description_text7')}</p>
                                <p style={{ textAlign: 'left' }} className="ResultTable__textbelow">{t('var4_description_text8')} </p>
                            </>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}