import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer/Footer';
import Header from './Header';
import classes from './Layout.module.scss'


const Layout = ({ setShowModal }) => {
    return (
        <>
            <Header setShowModal={setShowModal} />
            <div className={classes.wrapper}>
                <main >
                    <Outlet />
                </main>
            </div>

            <Footer />
        </>
    )
}

export default Layout;