import './Camera.scss'
import Button from '../../components/buttons/Button';
import { Link, useParams } from 'react-router-dom';
import React, { useRef, useEffect, useState } from 'react';
import * as faceapi from "face-api.js";
import { useTranslation } from 'react-i18next';

const Camera = () => {
    const {t,i18n} = useTranslation();
    const videoRefs = useRef();
    const canvasRefs = useRef();
    const { id } = useParams();
    let link = '';
    if (id) {
        link = `/test?id=${id}`;
    }
    if (!id) {
        link = `/quiz`;
    }

    const [One, setOne] = useState(false);

    // const NextData = useLocation();
    // const NextId = NextData.search.slice(4);

    // const NextPage = () => {
    //     window.location.href = `/test?id=${NextId}`;
    // }

    useEffect(() => {
        const loadModels = () => {
            Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
                faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
                faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
                faceapi.nets.faceExpressionNet.loadFromUri('/models'),
            ]).then(() => {
                faceDetection();
            })
        };
        startVideo();

        videoRefs && loadModels();

    }, []);



    const startVideo = () => {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((currentStream) => {
                videoRefs.current.srcObject = currentStream;
            })
            .catch((err) => {
                console.error(err)
            });
    }

    const faceDetection = async () => {
        setInterval(async () => {
            const detections = await faceapi.detectAllFaces(videoRefs.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();
            const FirstSpan = document.getElementById('f');
            const max = Math.max(...Object.values(detections[0].expressions))
            for (let prop in detections[0].expressions) {
    
                if (detections[0].expressions[prop] === max) {
                    if (prop === t('smilecamera') || 'happy') {
                        setOne(true);
                        FirstSpan.style.backgroundColor = '#08D18A';
                    }

                }

            }

            canvasRefs.current.innerHtml = faceapi.createCanvasFromMedia(videoRefs.current);
            faceapi.matchDimensions(canvasRefs.current, {
                width: videoRefs.current.clientWidth,
                height: videoRefs.current.clientHeight,
            })

            const resized = faceapi.resizeResults(detections, {
                width: videoRefs.current.clientWidth,
                height: videoRefs.current.clientHeight,
            });

            faceapi.draw.drawDetections(canvasRefs.current, resized)
            faceapi.draw.drawFaceLandmarks(canvasRefs.current, resized)
            // faceapi.draw.drawFaceExpressions(canvasRefs.current, resized)

        }, 1000)
    }
    return (
        <>
            <section className="camera_block">
                <div className="container">
                    <div className="camera_block__header">
                        <h2 className="camera_block__header__title PADDING__CHANGE">{t('calibrateemotion_title')}</h2>
                        <p className="camera_block__header__description">{t('calibrateemotion_description')}</p>
                    </div>
                    <p className='block__smalltitle'>{t('calibrateemotion_text1')}</p>
                    <p className='block__smalltitle'>{t('calibrateemotion_text2')}</p>
                    <div id="video-containers">
                        <video className='videoElement' crossOrigin='anonymous' ref={videoRefs} playsInline autoPlay ></video>
                        <canvas className='canvasElement' ref={canvasRefs} />

                    </div>

                    <div className='camera_block__buttons'>
                        {!One && <div className="after_video_emotions">{t('bestsmile')}</div>}
                        {One && <div className="after_video_emotions">{t('nowstarttest')}</div>}
                        <div className="after_video__dots"><span id='f'></span></div>
                        <p id='element' className='block__smalltitle__red'>{t('heart_noface')}</p>
                        {One &&
                            <Link reloadDocument to={link}>
                                <Button id='finish'>{t('calibrateemotion_nextbutton')}</Button>
                            </Link>
                        }

                    </div>

                </div>
            </section>
        </>
    )
}

export default Camera