import React, { useEffect, useState } from 'react'
import { BiMenuAltRight } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import classes from "./header.module.scss";
import logo from './../assets/main_page/logo.svg'
import { Link } from "react-scroll";
import { Link as Linkto, useLocation, useParams } from "react-router-dom";
import Button from './buttons/Button';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from './ChangeLanguage';

const Header = ({ setShowModal }) => {
    const { pathname } = useLocation();
    const { id } = useParams();
    const IDData = useLocation();
    const IdWhatweNeed = IDData.search.slice(4);
    const [menuOpen, setMenuOpen] = useState(false);
    const [size, setSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const handleResize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (size.width > 991 && menuOpen) {
            setMenuOpen(false);
        }
    }, [size.width, menuOpen]);
    const OpenPayPal = () => {
        if (size.width < 991 && menuOpen) {
            setMenuOpen((p) => !p);
        }
        setShowModal(true)
    }
    const menuToggleHandler = () => {
        setMenuOpen((p) => !p);
    };
    const { t, i18n } = useTranslation();

    return (
        <header className={classes.header}>
            <div className={classes.header__content}>
                {(pathname === '/' || pathname === '/quiz' || pathname === '/calibration' || pathname === '/camera') && !id ?
                    <>
                        <Linkto reloadDocument to="/">
                            <div className={classes.header__logo}>
                                <img src={logo} alt="" className={classes.header__logo__image} />
                                <h2 className={classes.header__logo__text}>BeSure</h2>
                            </div>
                        </Linkto>
                        {!IdWhatweNeed && pathname !== '/' && <Button onClick={() => setShowModal(true)}
                            // className={classes.TryNowHeader}
                            id='HeaderButton' style={{ margin: '0px', padding: '15px 35px' }} >{t('go_test')}</Button>}
                    </>
                    :
                    <> <div className={classes.header__logo}>
                        <img src={logo} alt="" className={classes.header__logo__image} />
                        <h2 className={classes.header__logo__text}>BeSure</h2>
                    </div></>
                }
                {pathname === '/' && <Button onClick={() => setShowModal(true)} className={classes.TryNowHeader} id='HeaderButton' style={{ margin: '0px', padding: '15px 15px' }} >{t('go_test')}</Button>}
                {pathname === '/demo' && <Button onClick={() => setShowModal(true)} id='HeaderButton' style={{ margin: '0px', padding: '15px 45px' }} >{t('go_test')}</Button>}
                {/* <Linkto className={classes.TryNowHeader} to='/instruction'> */}


                {/* </Linkto> */}


                {pathname === '/' && <nav className={`${classes.header__content__nav} ${menuOpen ? classes.isMenu : ''}`}>
                    <ul>
                        <li>
                            <Link to="Home" spy={true} smooth={true} duration={500} id='Homenav' onClick={menuToggleHandler} >  {t("home")}</Link>
                        </li>
                        <li>
                            <Link to="HowWorks" spy={true} smooth={true} duration={500} id='ItWorksnav' onClick={menuToggleHandler}> {t("HOW")}</Link>
                        </li>
                        <li>
                            <Link to="ItWork" spy={true} smooth={true} duration={500} id='Flownav' onClick={menuToggleHandler}>{t("TODO")}</Link>
                        </li>
                        <li>
                            <Link to="FAQ" spy={true} smooth={true} duration={500} id='Faqnav' onClick={menuToggleHandler}>{t("FAQ")}</Link>
                        </li>
                        <li>
                            <Button id='BuyNownav' onClick={OpenPayPal} style={{ margin: '0px', padding: '20px 55px' }} >{t("go_test")}</Button>


                        </li>
                        <li>
                            <ChangeLanguage />

                        </li>
                    </ul>
                </nav>}
                {pathname !== '/' && <nav className={`${classes.header__content__nav} ${menuOpen ? classes.isMenu : ''}`}>
                    <ul>
                        <li>
                            <ChangeLanguage />
                        </li>
                    </ul>
                </nav>}

                {(pathname === '/' || pathname === '/test' || pathname.startsWith('/result') || pathname === '/calibration' || pathname === '/camera') &&
                    <div className={classes.header__content__toggle}>
                        {menuOpen ?
                            <AiOutlineClose onClick={menuToggleHandler} /> :
                            <BiMenuAltRight onClick={menuToggleHandler} />}
                    </div>}
            </div>
        </header>
    )
}

export default Header