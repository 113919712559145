
import React, { useState } from "react";
import "./FormRegistrate.scss";
import { ModalNoGo, ModalBody, ModalFooter, ModalHeader } from "../../components/popup/Popup";
import Button, { ButtonIcon } from "../../components/buttons/Button";
import { Form } from "../../components/popup/Form/Form";
import copy from '../../assets/popups/copy.svg'
import { useTranslation } from 'react-i18next';


const FormRegistrate = () => {
    const {t,i18n} = useTranslation();
    const [showModal, setShowModal] = useState(true);
    const [Copied, setCopied] = useState(t('copybutton'));
    const [step, setStep] = useState(2);
    const [urlParam, seturlParam] = useState('');
    const URLtoCOPY = `${process.env.REACT_APP_SITE}/instruction/${urlParam}`;
 
    const goNext = () => {
        window.location.href = URLtoCOPY;
    }
    const copyText = async () => {
        await navigator.clipboard.writeText(URLtoCOPY);
        setCopied(t('copybutton_result'));
    }

    return (
        <section className="page__diagnose" >

            <div className="container">


                {step === 2 && <ModalNoGo
                    show={showModal}
                    setShow={setShowModal}>



                    <>
                        <ModalHeader>
                            <h3>{t('get_results')}</h3>
                        </ModalHeader>
                        <ModalBody>
                            <Form thissetStep={setStep} seturlParam={seturlParam} />
                        </ModalBody>
                        <ModalFooter>

                            <Button
                                className='btn-violet' style={{ margin: '0 auto' }} form='main-form'>{t('main_sec_button')}</Button>

                        </ModalFooter>


                    </>


                </ModalNoGo>
                }
                {step === 4 && <ModalNoGo
                    show={showModal}
                    setShow={setShowModal}
                >

                    <ModalHeader>
                        <h3 className='modal-h3'>{t('popup_titlefinish')}</h3>
                    </ModalHeader>
                    <ModalBody>
                        <p className='modal-p'>{t('popup_descriptionfinish')}</p>
                    </ModalBody>
                    <ModalFooter>
                        <div className='buttons__elements'>
                            <ButtonIcon className='btn-grey' onClick={copyText} icon={copy} classNameIcon='icon_padding'>{Copied}</ButtonIcon>
                            <Button className='btn-vilet' onClick={goNext} >{t('nextbutton')}</Button>
                        </div>
                    </ModalFooter>
                </ModalNoGo>
                }

            </div>

        </section>
    );

};

export default FormRegistrate;
