import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './style/index.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <Routes>
            <Route path="*" element={<App />} />
        </Routes>
    </Router>
);