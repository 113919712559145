import React from 'react';
import line from './../../assets/FAQ/line.svg'
import './Faq.scss';
import eye from './../../assets/FAQ/eye.jpg'
import Button from '../buttons/Button';
import { useTranslation } from "react-i18next";
const FAQ = ({ setShowModal }) => {
    const {t,i18n} = useTranslation();
    return (

        <section id='FAQ' className="block_faq" >
            <div className="container">
                <div className="block_faq__header">
                    <h2 className="block_faq__header__title">{t('faq_title')}</h2>
                    <img src={line} alt="line" className="block_faq__header__img" />
                    <p className="block_faq__header__description">{t('faq_description')}</p>
                </div>

                <div className='block_faq__main'>
                    <div className="block_faq__accordeon">
                        <div className="tabs">
                            <div className="tab">
                                <input type="checkbox" id="chck1" />
                                <label className="tab-label" htmlFor="chck1">{t('questionW_1')}</label>
                                <div className="tab-content" id='tab1'>
                                    <ol>
                                        <li>{t('questionW_1_1')}</li>
                                        <li>{t('questionW_1_2')}</li>
                                        <li>{t('questionW_1_3')}</li>
                                        <li>{t('questionW_1_4')}</li>
                                        <li>{t('questionW_1_5')}</li>
                                        <li>{t('questionW_1_6')}</li>
                                        <li>{t('questionW_1_7')}</li>
                                        <li>{t('questionW_1_8')}</li>
                                        <li>{t('questionW_1_9')}</li>
                                        <li>{t('questionW_1_10')}</li>
                                        <li>{t('questionW_1_11')}</li>
                                        <li>{t('questionW_1_12')}</li>
                                        <li>{t('questionW_1_13')}</li>
                                        <li>{t('questionW_1_14')}</li>
                                        <li>{t('questionW_1_15')}</li>
                                        <li>{t('questionW_1_16')}</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="tab">
                                <input type="checkbox" id="chck2" />
                                <label className="tab-label" htmlFor="chck2">{t('questionM_1')}</label>
                                <div className="tab-content" id='tab2'>
                                    <ol>
                                        <li>{t('questionM_1_1')}</li>
                                        <li>{t('questionM_1_2')}</li>
                                        <li>{t('questionM_1_3')}</li>
                                        <li>{t('questionM_1_4')}</li>
                                        <li>{t('questionM_1_5')}</li>
                                        <li>{t('questionM_1_6')}</li>
                                        <li>{t('questionM_1_7')}</li>
                                        <li>{t('questionM_1_8')}</li>
                                        <li>{t('questionM_1_9')}</li>
                                        <li>{t('questionM_1_10')}</li>
                                        <li>{t('questionM_1_11')}</li>
                                        <li>{t('questionM_1_12')}</li>
                                        <li>{t('questionM_1_13')}</li>
                                        <li>{t('questionM_1_14')}</li>
                                        <li>{t('questionM_1_15')}</li>
                                        <li>{t('questionM_1_16')}</li>
                                        <li>{t('questionM_1_17')}</li>
                                    </ol>

                                </div>

                            </div>
                            <div className="tab">
                                <input type="checkbox" id="chck3" />
                                <label className="tab-label" htmlFor="chck3">{t('question_2')}</label>
                                <div className="tab-content" id='tab3'>
                                    <h3>{t('question_2_1_title')}</h3>
                                    <ol>
                                        <li>{t('question_2_1_1')} </li>
                                        <li>{t('question_2_1_2')}</li>
                                        <li>{t('question_2_1_3')} </li>
                                        <li>{t('question_2_1_4')} </li>
                                    </ol>
                                    <h3>{t('question_2_2_title')}</h3>
                                    <ol>
                                        <li>{t('question_2_2_1')}</li>
                                    </ol>
                                    <h3>{t('question_2_3_title')}</h3>
                                    <ol>
                                        <li>{t('question_2_3_1')} </li>
                                        <li>{t('question_2_3_2')} </li>
                                        <li>{t('question_2_3_3')} </li>
                                        <li>{t('question_2_3_4')}</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="tab">
                                <input type="checkbox" id="chck4" />
                                <label className="tab-label" htmlFor="chck4">{t('question_3')}</label>
                                <div className="tab-content" id='tab4'>
                                    <h3>{t('question_3_1_title')} </h3>
                                    <ul>
                                        <li>{t('question_3_1_1')}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab">
                                <input type="checkbox" id="chck6" />
                                <label className="tab-label" htmlFor="chck6">{t('question_4')}</label>
                                <div className="tab-content" id='tab7'>
                                    <p>{t('question_4_1_1')}</p>
                                    <img src={eye} alt="eye" />
                                    <p>{t('question_4_1_2')}</p>

                                    <p>{t('question_4_1_3')} </p>
                                    <p>{t('question_4_1_4')} </p>
                                    <p>{t('question_4_1_5')}</p>
                                </div>
                            </div>
                            <div className="tab">
                                <input type="checkbox" id="chck7" />
                                <label className="tab-label" htmlFor="chck7">{t('question_5')}</label>
                                <div className="tab-content" id='tab8'>
                                    <p>{t('question_5_1_1')}</p>
                                    <p>{t('question_5_1_2')}</p>

                                    <p>{t('question_5_1_3')} </p>
                                    <p>{t('question_5_1_4')} </p>
                                    <p>{t('question_5_1_5')}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <Button onClick={() => setShowModal(true)} id='FaqTryButton' style={{ margin: "0 auto", marginTop: "25px" }}>{t('start_button')}</Button>

                </div>

            </div>
        </section >
    )
}

export default FAQ