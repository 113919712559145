import React from 'react'
import './Bubble.scss'
import { useTranslation } from "react-i18next";

export const Bubble = () => {
    const {t,i18n} = useTranslation();
    return (
        <div className="Bubble">
            <div className="Bubble__point"></div>
            <div className="Bubble__text">
                <p>{t("nocamera")}</p>
                <p>{t("nocamera1")}</p>
                <p> {t("nocamera2")}</p>
            </div>

        </div>
    )
}