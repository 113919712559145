import React, { useState } from 'react'
import './Eyetracker.scss'
import { Helmet } from 'react-helmet';
import Button from '../../components/buttons/Button';
import { Link, useLocation } from 'react-router-dom';


const Eyetracker = () => {
    const [Show, setShow] = useState(false);
    const NextData = useLocation();
    const NextId = NextData.search.slice(4);
    let link = `/camera/${NextId}`;

    if (NextId) {
        link = `/camera/${NextId}`;
    }
    if (!NextId) {
        link = `/camera`;
    }
    const NextPage = () => {
        window.location.href = link;
    }
    const showTopleft = () => {
        const Topleft = document.getElementById('Topleft');
        Topleft.style.visibility = 'visible';
    }
    const showTopright = () => {
        const Topright = document.getElementById('Topright');
        Topright.style.visibility = 'visible';
    }
    const showBottomLeft = () => {
        const BottomLeft = document.getElementById('BottomLeft');
        BottomLeft.style.visibility = 'visible';
    }
    const showBottomRight = () => {
        const BottomRight = document.getElementById('BottomRight');
        BottomRight.style.visibility = 'visible';
    }
    const showButton = () => {

        setShow(true);
    }
    setTimeout(showTopleft, 4000);
    setTimeout(showTopright, 8000);
    setTimeout(showBottomLeft, 12000);
    setTimeout(showBottomRight, 16000);

    setTimeout(showButton, 20000);
    return (
        <>
            <Helmet>
                <script defer src="pupl/camvas.js"></script>
                <script defer src="pupl/pico.js"></script>
                <script defer src="pupl/lploc.js"></script>
                <script defer src="pupl/startcamera.js"></script>
            </Helmet>

            <section className="Eyetracker_block">
                <div className="container">
                    <div className="Eyetracker_block__header">
                        <h2 className="Eyetracker_block__header__title PADDING__CHANGE">Трекер погляду</h2>
                    </div>

                    <p className='block__smalltitle'>Забезпечте достатнє освітлення</p>
                    <div id="video-container">
                        <div className='eyetracker_dots'><div className="after_video__dots"><span id='Topleft'></span><span id='Topright'></span></div></div>
                        <center>
                            {/* <video playsInline id="webcam" className='webcam_calibration' width="640" height="480"></video> */}
                            <p><center><canvas id='canvasEye'></canvas></center></p>
                        </center>
                        <div className='eyetracker_dots'><div className="after_video__dots"><span id='BottomLeft'></span><span id='BottomRight'></span></div></div>
                    </div>
                    <div className='Eyetracker_block__buttons'>
                        <p id='element' className='block__smalltitle__red'>Обличчя не видно</p>
                        {Show && <Link reloadDocument to={link}>
                            <Button onClick={NextPage} id='finish'>Далі</Button>
                        </Link>}
                    </div>
                </div>
            </section >
        </>
    )
}

export default Eyetracker