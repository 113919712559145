import React, { useEffect, useState, useRef } from "react";
import "./Table.scss";
import line from "./../../../assets/Howitworks/line.svg";
import { ResultTable } from "../Result";
import * as faceapi from "face-api.js";
import Button from "../../buttons/Button";
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import { useParams } from "react-router-dom";
import lottie from "lottie-web";
import { Link } from "react-scroll";
import load from "./../../../assets/loading_white.json";
import Chart from "../Chart/Chart";
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell } from "recharts";
import { useTranslation } from 'react-i18next';

const COLORS = ["#707070", "#ffe599", "#6e339c", "#ea4335", "#fc8403", "#0fd153", "#0fd1ce"];
// "neutral", "happy", "sad", "angry", "fearful", "disgusted", "surprised"

const AppPieChart = (
  { data,
    textData }
) => {

  // let maxValueEmote = Math.max(...date.map(element => element.value))

  // let objectFind = date.find(o => o.value === maxValueEmote);
  const { t, i18n } = useTranslation();
  return (
    <div className='Bublik' style={{ width: '100%' }}>
      <ResponsiveContainer>
        <PieChart>
          <text x={'50%'} y={'50%'} textAnchor="middle" dominantBaseline="middle">
            {textData}
          </text>
          <Pie
            data={data}
            fill="#8884d8"
            innerRadius={'43%'}
            outerRadius={'65%'}
            paddingAngle={1}
            dataKey="value"
          >
            {data.length === 7 ? data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            )) : <Cell fill={'#707070'} />}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

const Table = () => {
  let variable;
  const { t, i18n } = useTranslation();
  const TableEmotions = useRef()
  const [loading, setloading] = useState([]);
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [Expressions, setExpressions] = useState([]);
  const [sendEmail, setSendEmail] = useState(t('result_button_link'));
  const [numbResult, setnumbResult] = useState(0);
  const initialState = [];
  const [arrayBPM, setarrayBPM] = useState(initialState);
  const [FinisharrayBPM, setFinisharrayBPM] = useState(initialState);
  const componentRef = useRef();
  let enpoints;

  let localID = localStorage.getItem('id');

  if (id !== 'fff') {
    enpoints = `${process.env.REACT_APP_API}/answers/${id}`;
  }
  if (id === 'fff') {
    enpoints = `${process.env.REACT_APP_API}/answers/${localID}`;
  }

  const handleSendEmail = () => {
    let finishdata = `${process.env.REACT_APP_API}/ukraine/resultua/`;

    setSendEmail(t('wassend'));
    axios.get(`${finishdata}${localID}`)
      .then(response => {
      })
      .catch((error) => {
        console.log(error);
      })
  }

  let elem = 0;
  let maxElem = 0;
  useEffect(() => {
    const fetchdata = () => {
      axios.get(enpoints)
        .then(response => {

          for (let i = 0;i < response.data.length;i++) {
            setList((arr) => [
              ...arr,
              {
                id: `${i + 1}`,
                question: JSON.parse(response.data[i].title),
                answer: JSON.parse(response.data[i].answer),
                reaction: response.data[i].reaction,
              },
            ]);


            let obj = { Question: `Q ${i + 1}`, Beats: response.data[i].bpm, }
            setarrayBPM(current => [...current, obj]);

            if (response.data[i].type === '' || response.data[i].type === ' ' || response.data[i].type === '  ') {
              elem = elem + response.data[i].points;
            } else {
              maxElem = Math.max(maxElem, response.data[i].points);
            }
            setnumbResult(Math.max(elem, maxElem));
          }

        }).catch(error => console.log(error))
    }
    lottie.loadAnimation({
      container: document.querySelector("#loading-anim"),
      animationData: load,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    setTimeout(() => {
      setloading(false);
    }, 10000);
    fetchdata();


  }, []);

  useEffect(() => {
    let result = arrayBPM.map(a => a.Beats);

    // Вычисляем среднее значение сердцебиений
    let totalSum = result.reduce((acc, val) => acc + val, 0);
    let averageBPM = totalSum / result.length;

    // Находим допустимый диапазон (±25% от среднего)
    const lowerBound = averageBPM * 0.75;
    const upperBound = averageBPM * 1.25;

    // Фильтруем значения, которые находятся в пределах ±25% от среднего
    let filteredResults = result.filter(val => val >= lowerBound && val <= upperBound);

    // Если нет данных после фильтрации, выходим
    if (filteredResults.length === 0) return;

    // Вычисляем новое среднее по отфильтрованным данным
    let filteredSum = filteredResults.reduce((acc, val) => acc + val, 0);
    let normalizedAverageBPM = filteredSum / filteredResults.length;

    // Находим максимальное и минимальное значения для нормализации
    let maxFiltered = Math.max(...filteredResults);
    let minFiltered = Math.min(...filteredResults);

    // Нормализуем значения в диапазоне от 0 до 100
    for (let [index, value] of result.entries()) {
      let normalizedValue;

      if (value >= lowerBound && value <= upperBound) {
        // Нормализуем значения, находящиеся в пределах ±25%
        normalizedValue = 50 + ((value - normalizedAverageBPM) / (maxFiltered - minFiltered)) * 50;
      } else {
        // Для значений вне диапазона оставляем аномальные значения как есть
        normalizedValue = value > averageBPM ? 100 : 0; // Аномалии — 0 или 100
      }

      let obj = { Question: `Q ${index + 1}`, Beats: Math.round(normalizedValue) };
      setFinisharrayBPM(current => [...current, obj]);
    }
  }, [arrayBPM]);

  useEffect(() => {

    const handleImage = async () => {
      const images = document.querySelectorAll('.image__table');

      const canvases = document.querySelectorAll('.canvas_table');

      const emotions = document.querySelectorAll('.emotion_detection');




      for (let image = 0;image < images.length;image++) {
        const useTinyModel = true
        const detections = await faceapi
          .detectSingleFace(images[image], new faceapi.TinyFaceDetectorOptions())
          .withFaceLandmarks(useTinyModel)
          .withFaceExpressions();
        canvases[image].innerHtml = faceapi.createCanvasFromMedia(images[image]);
        if (detections?.expressions) {
          let arr = Object.values(detections.expressions);
          let keys = Object.keys(detections.expressions);

          let objectd = [];

          for (var i = 0;i < keys.length;i++) {
            let keyValue = parseFloat(arr[i]).toFixed(3);

            variable = keys[i] === 'neutral' ? t('camera_text_neutral') :
              keys[i] === 'happy' ? t('camera_text_happy') :
                keys[i] === 'sad' ? t('camera_text_sad') :
                  keys[i] === 'angry' ? t('camera_text_angry') :
                    keys[i] === 'fearful' ? t('camera_text_fearful') :
                      keys[i] === 'surprised' ? t('camera_text_surprised') :
                        keys[i] === 'disgusted' ? t('camera_text_disgusted') : t('demo_unknown');
            objectd.push({ 'name': `${variable}`, 'value': Number(keyValue) });
          }

          setExpressions(prevState => [...prevState, objectd]);

          let max = Math.max(...arr);
          for (let prop in detections.expressions) {
            if (detections.expressions[prop] === max) {
              if (detections.expressions[prop] > 0.50) {
                let newText = prop === 'neutral' ? t('camera_text_neutral') :
                  prop === 'happy' ? t('camera_text_happy') :
                    prop === 'sad' ? t('camera_text_sad') :
                      prop === 'angry' ? t('camera_text_angry') :
                        prop === 'fearful' ? t('camera_text_fearful') :
                          prop === 'surprised' ? t('camera_text_surprised') :
                            prop === 'disgusted' ? t('camera_text_disgusted') : t('demo_unknown');
                emotions[image].innerText = newText;
              } else if (detections.expressions[prop] > 0.30) {
                emotions[image].innerText = t('mixedemotion');

              }
              else {
                emotions[image].innerText = t('facehidden');
              }


            } else if (detections.expressions[prop] === undefined) {
              emotions[image].innerText = t('heart_noface');
              break
            }

          }
        } else {
          let objectd = [
            { 'name': t('unknown'), 'value': 1 },
            { 'name': t('camera_text_neutral'), 'value': 0 },
            { 'name': t('camera_text_neutral'), 'value': 0 },
            { 'name': t('camera_text_neutral'), 'value': 0 },
            { 'name': t('camera_text_neutral'), 'value': 0 },
            { 'name': t('camera_text_neutral'), 'value': 0 },
            { 'name': t('camera_text_neutral'), 'value': 0 }
          ]
          setExpressions(prevState => [...prevState, objectd]);
          emotions[image].innerText = t('facehidden');
          continue
        }
        // emotions[image].innerText = findExpres();
        faceapi.matchDimensions(canvases[image], {
          width: images[image].scrollWidth,
          height: images[image].scrollHeight
        })

        const resized = faceapi.resizeResults(detections, {
          width: images[image].scrollWidth,
          height: images[image].scrollHeight
        })
        faceapi.draw.drawDetections(canvases[image], resized)
        faceapi.draw.drawFaceLandmarks(canvases[image], resized)

      }
    };

    let loadModels = async () => {

      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
        faceapi.nets.faceLandmark68TinyNet.loadFromUri("/models"),
        faceapi.nets.faceExpressionNet.loadFromUri("/models"),
      ])
      handleImage().catch((e) => console.log(e));
    };
    (loadModels());
  }, [loading], [i18n.language]);
  const colNames = [t('result_table_names'), t('result_table_question'), t('result_table_answer'), t('result_table_reaction')];

  const Addimage = (item, index, index2) => {

    if (typeof item === 'object') {
      if (item[i18n.language].substring(0, t('yes').length) === t('yes')) {
        return (
          <>
            {Expressions.length === list.length ? <AppPieChart textData={t('yes')} data={Expressions[parseInt(index[0]) - 1]} /> : <></>}
          </>

        )
      }
      if (item[i18n.language].substring(0, t('no').length) === t('no')) {
        return (
          <>
            {Expressions.length === list.length ? <AppPieChart textData={t('no')} data={Expressions[parseInt(index[0]) - 1]} /> : <></>}
          </>

        )
      }
      if (item[i18n.language]) {
        return (
          <>
            <p>{JSON.stringify(item[i18n.language])}</p>
          </>

        )
      }

    }
    if (typeof item === 'string') {
      if (item.toString().substring(0, 4) === "data") {
        return (
          <>
            <div className="image__canvas_elements">
              <img
                cross-origin="anonymous"
                // ref={(el) => (imgRef.current[index2] = el)}
                id={`img${parseInt(index[0])}`}
                className="image__table"
                src={item}
                alt={t('photowithreaction')}
              />
              <canvas
                // ref={(el) => (canvasRef.current[index2] = el)}
                id={`canvas${parseInt(index[0])}`}
                className="canvas_table"
              ></canvas>
            </div>

            <Link to="emotions" spy={true} smooth={true} duration={500}>
              <p className="emotion_detection">{t('calculatestill')}</p>
            </Link>

          </>
        );
      } else {
        return item;
      }
    }

  };
  return (
    <>
      {loading && <div className="loading">
        <div id="loading-anim" className="img_loading" />
      </div>}
      <div ref={componentRef}>
        {numbResult > 0 && <ResultTable list={list} numbResult={numbResult} />}
        <section style={{ opacity: loading ? '0' : '1' }} className="block__Table">
          <div className="container">
            {FinisharrayBPM.length !== 0 && <Chart dataforpoint={list} titleAxis={t("question_quantity")} height={3500} data={FinisharrayBPM} />}
            <div className="block__Table__Head">
              <h2 className="block__Table__title">{t("answerreaction")}</h2>
              <img src={line} alt="line" className="block__Table__line" />
            </div>
            {list.length > 0 && (
              <table className="Board" cellSpacing="0">
                <thead className="Board__head">
                  <tr className="Board__line">
                    {colNames.map((headerItem, index) => (
                      <th className="Board__cell" key={index}>
                        {headerItem.toUpperCase()}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="Body">
                  {Object.values(list).map((obj, index) => (
                    <tr className="Body__line" key={index}>
                      {Object.values(obj).map((value, index2, indexed = index) => (
                        <td className="Body__cell" key={index2}>
                          {Addimage(value, indexed, index2)}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <div id="emotions" ref={TableEmotions} style={{ paddingTop: '30px' }} className="block__Table__Head">
              <h2 className="block__Table__title">{t('result_title_emotions')}</h2>
              <img src={line} alt="line" className="block__Table__line" />
            </div>
            <table className="Board" cellSpacing="0">
              <thead className="Board__head">
                <tr className="Board__line">
                  <th className="Board__cell" >
                    {t('result_emotions_color')}
                  </th>
                  <th className="Board__cell" >
                    {t('result_emotions_name')}
                  </th>
                  <th className="Board__cell" >
                    {t('result_emotions_description')}
                  </th>
                </tr>
              </thead>
              <tbody className="Body">
                <tr className="Body__line" >
                  <td className="Body__cell" >
                    <div className='color_table angry'></div>
                  </td>
                  <td className="Body__cell" >
                    <strong>{t('result_emotion1')}</strong><br />{t('result_emotion1_description')}
                  </td>
                  <td className="Body__cell" >{t("result_emotion1_interpretation")}</td>
                </tr>
                <tr className="Body__line" >
                  <td className="Body__cell" >
                    <div className='color_table  fearful'></div>
                  </td>
                  <td className="Body__cell" >
                    <strong>{t('result_emotion2')}</strong><br />{t('result_emotion2_description')}
                  </td>
                  <td className="Body__cell" >
                    {t('result_emotion2_interpretation')}
                  </td>
                </tr>
                <tr className="Body__line" >
                  <td className="Body__cell" >
                    <div className='color_table disgusted'></div>
                  </td>
                  <td className="Body__cell" >
                    <strong>{t('result_emotion3')}</strong><br />{t('result_emotion3_description')}
                  </td>
                  <td className="Body__cell" >
                    {t("result_emotion3_interpretation")}
                  </td>
                </tr>
                <tr className="Body__line" >
                  <td className="Body__cell" >
                    <div className='color_table surprised'></div>
                  </td>
                  <td className="Body__cell" >
                    <strong>{t('result_emotion4')}</strong><br />{t('result_emotion4_description')}
                  </td>
                  <td className="Body__cell" >
                    {t('result_emotion4_interpretation')}
                  </td>
                </tr>
                <tr className="Body__line" >
                  <td className="Body__cell" >
                    <div className='color_table happy'></div>
                  </td>
                  <td className="Body__cell" >
                    <strong>{t('result_emotion5')}</strong><br />{t('result_emotion5_description')}
                  </td>
                  <td className="Body__cell" >
                    {t('result_emotion5_interpretation')}
                  </td>
                </tr>
                <tr className="Body__line" >
                  <td className="Body__cell" >
                    <div className='color_table neutral'></div>
                  </td>
                  <td className="Body__cell" >
                    <strong> {t('result_emotion6')}</strong><br />{t('result_emotion6_description')}

                  </td>
                  <td className="Body__cell" >
                    {t('result_emotion6_interpretation')}
                  </td>
                </tr>
                <tr className="Body__line" >
                  <td className="Body__cell" >
                    <div className='color_table sad'></div>
                  </td>
                  <td className="Body__cell" >
                    <strong>{t('result_emotion7')}</strong><br />{t('result_emotion7_description')}
                  </td>
                  <td className="Body__cell" >
                    {t('result_emotion7_interpretation')}
                  </td>
                </tr>
              </tbody>
            </table>


            <div className="block__Table__buttons">
              <ReactToPrint removeAfterPrint
                trigger={() => <Button >{t('result_button_pdf')}</Button>}
                content={() => componentRef.current} />
              <Button style={{ backgroundColor: '#0fd1ce' }} onCLick={handleSendEmail}>{t('result_button_link')}</Button>
            </div>


          </div>
        </section>
      </div>
    </>
  );
};

export default Table;
