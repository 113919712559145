import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import lottie from "lottie-web";
import load from "./../../assets/loading_white.json";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './PartnerResult.scss';
export const PartnerResult = () => {
    const { id } = useParams();
    const [resultTitle, setresultTitle] = useState('');
    const [loading, setloading] = useState(true);
    const [description, setdescription] = useState(0);
    const initialState = [];
    const [list, setList] = useState([]);
    const {t,i18n} = useTranslation();
    const [arrayBPM, setarrayBPM] = useState(initialState);
    const [numbResult, setnumbResult] = useState(0);
    let enpoints = `${process.env.REACT_APP_API}/answers/${id}`;
    let elem = 0;
    let maxElem = 0;
    useEffect(() => {
        const fetchdata = () => {
            axios.get(enpoints)
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        setList((arr) => [
                            ...arr,
                            {
                                id: `${i + 1}`,
                                question: response.data[i].title,
                                answer: response.data[i].answer,
                                reaction: response.data[i].reaction,
                            },
                        ]);
                        // setSufficient(Math.max(Sufficient, response.data[i].points));

                        // setInSufficient(InSufficient + response.data[i].points);

                        let obj = { Question: `Q ${i + 1}`, Beats: response.data[i].bpm, }
                        setarrayBPM(current => [...current, obj]);

                        if (response.data[i].type === '' || response.data[i].type === ' ' || response.data[i].type === '  ') {
                            elem = elem + response.data[i].points;
                        } else {
                            maxElem = Math.max(maxElem, response.data[i].points);
                            // setSufficient(Math.max(Sufficient, response.data[i].points));
                        }
                        setnumbResult(Math.max(elem, maxElem));
                    }


                }).catch(error => console.log(error))
        }
        lottie.loadAnimation({
            container: document.querySelector("#loading-anim"),
            animationData: load,
            renderer: "svg", // "canvas", "html"
            loop: true, // boolean
            autoplay: true, // boolean
        });
        setTimeout(() => {
            setloading(false);
        }, 10000);
        fetchdata();


    }, []);

    useEffect(() => {
        const startnumb = () => {
            if (numbResult < 25) {
                setresultTitle(t('probability1'));
                setdescription(1);

            } else if (numbResult < 50) {
                setresultTitle(t('probability2'));
                setdescription(2);
            } else if (numbResult <= 75) {
                setresultTitle(t('probability3'));
                setdescription(3);
            } else {
                setresultTitle(t('probability4'));
                setdescription(4);
            }
        }
        startnumb();
    }, [numbResult])

    return (
        <>
            {loading && <div className="loading">
                <div id="loading-anim" className="img_loading" />
            </div>}

            <section className="PartnerResult">
                <div className="container">
                    <div className="PartnerResult__main">
                        <div className="PartnerResult__body">
                            <div className="PartnerResult__animation">
                            </div>
                            {description === 1 &&
                                <>
                                    <p style={{ textAlign: 'left', fontWeight: 'bold' }} className="PartnerResult__textbelow"> 
                                    </p>
                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow" >{t('standart_text1')}</p>
                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow">{t('variant1_text1')}</p>

                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow">{t('variant1_text2')}</p>
                                </>
                            }
                            {description === 2 &&
                                <>
                                    <p style={{ textAlign: 'left', fontWeight: 'bold' }} className="PartnerResult__textbelow"> {t('thanks')}</p>
                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow" >{t('standart_text1')}</p>
                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow">{t('variant2_text1')}</p>
                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow">{t('variant2_text2')}</p>
                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow">{t('variant2_text3')}</p>
                                </>
                            }
                            {description === 3 &&
                                <>
                                    <p style={{ textAlign: 'left', fontWeight: 'bold' }} className="PartnerResult__textbelow">{t('thanks')}</p>
                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow" >{t('standart_text1')}</p>
                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow">{t('variant3_text1')}</p>
                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow">{t('variant3_text2')} </p>
                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow">{t('variant3_text3')} </p>
                                </>
                            }
                            {description === 4 &&
                                <>
                                    <p style={{ textAlign: 'left', fontWeight: 'bold' }} className="PartnerResult__textbelow">{t('thanks')}</p>
                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow" >{t('standart_text1')}</p>
                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow">{t('variant4_text1')}</p>
                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow">{t('variant4_text2')}</p>
                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow">{t('variant4_text3')}</p>
                                    <p style={{ textAlign: 'left' }} className="PartnerResult__textbelow">{t('variant4_text4')}</p>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}