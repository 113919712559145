import React, { useEffect, useRef, useState } from 'react'
import Button from "../../components/buttons/Button";
import "./../../details/Checkbox/Radio.scss";
import { Helmet } from 'react-helmet';
import { useLocation } from "react-router-dom";
import * as faceapi from "face-api.js";
import axios from 'axios';
import lottie from "lottie-web";
import load from "./../../assets/loading_white.json";
import { useTranslation } from 'react-i18next';

const Questions = ({ AboveQuestionText, finish, uuidFor, loading, personname, data, onAnswerUpdate, numberOfQuestions, activeQuestion, onSetActiveQuestion, onSetStep }) => {
    data = JSON.parse(data);

    const [selected, setSelected] = useState('');
    const [elementID, setElementID] = useState('');
    const [bpm, setbpm] = useState(0);
    const [questionNumber, setquestionNumber] = useState(0);
    const [error, setError] = useState('');
    const radiosWrapper = useRef();
    const idDatat = useLocation();
    const id = idDatat.search.slice(4);
    const videoRefQuestion = useRef();
    const canvasRefQuestion = useRef();
    const { t, i18n } = useTranslation();
    const faceDetection = async () => {
        setInterval(async () => {
            const detections = await faceapi.detectAllFaces(videoRefQuestion.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();
            // .withFaceExpressions();

            canvasRefQuestion.current.innerHtml = faceapi.createCanvasFromMedia(videoRefQuestion.current);
            faceapi.matchDimensions(canvasRefQuestion.current, {
                width: videoRefQuestion.current.clientWidth,
                height: canvasRefQuestion.current.clientHeight,
            })

            const resized = faceapi.resizeResults(detections, {
                width: videoRefQuestion.current.clientWidth,
                height: canvasRefQuestion.current.clientHeight,
            });

            faceapi.draw.drawDetections(canvasRefQuestion.current, resized)
            faceapi.draw.drawFaceLandmarks(canvasRefQuestion.current, resized)

        }, 1000)
    }

    const startVideo = () => {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((currentStream) => {
                videoRefQuestion.current.srcObject = currentStream;
            })
            .catch((err) => {
                alert(t('messageError'));
                window.location.reload();
                console.error(err)
            });
    }

    const loadModels = () => {
        Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
            faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
            faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
            // faceapi.nets.faceExpressionNet.loadFromUri('/models'),
        ]).then(() => {
            faceDetection();
        })
    };




    useEffect(() => {
        if (!id) {
            startVideo();

            videoRefQuestion && loadModels();
        }
        lottie.loadAnimation({
            container: document.querySelector("#react-logo"),
            animationData: load,
            renderer: "svg", // "canvas", "html"
            loop: true, // boolean
            autoplay: true, // boolean
        });
    }, []);




    const changeHandler = (text, points) => {
        setSelected(JSON.stringify(text));
        setElementID(points);
        if (error) {
            throw error;
        }
    }


    const nextClickHandler = (e) => {

        if (selected !== '') {
            setError('');
            onAnswerUpdate(prevState => [...prevState, { q: JSON.stringify(data[activeQuestion].title), a: selected }]);
            setSelected('');
            if (activeQuestion < numberOfQuestions - 1) {
                onSetActiveQuestion(activeQuestion + 1);
            } else {
                onSetStep(3);
            }
            if (id) {
                capture(activeQuestion)
            }

        } else {
            return setError(t('oneAnswerError'));
        }
        radiosWrapper.current.querySelector('input:checked').checked = false;

    }
    const handleKeyUp = (e) => {
        if (e.code === 'Space') {
            this.nextClickHandler(e);
        }
    }
    let numbers_of_bpm;
    const capture = () => {

        setbpm(bpm + 1)
        localStorage.setItem("QuestionNumber", JSON.stringify(bpm));
        let storedNames = JSON.parse(localStorage.getItem("bpm"));
        if (storedNames?.length) {
            numbers_of_bpm = Math.round(storedNames.reduce((prev, user) => prev + user, 0) / storedNames.length);
        } else {
            numbers_of_bpm = 75;
        }

        localStorage.removeItem('bpm');
        let canvas = document.getElementById('canvas_heart');
        let image = canvas.toDataURL('image/jpeg');
        let dataToSend = {
            master: id,
            title: JSON.stringify(data[activeQuestion].title),
            answer: selected,
            type: data[activeQuestion].type,
            reaction: image,
            points: parseInt(elementID),
            bpm: parseInt(numbers_of_bpm)
        }
        axios.post(`${process.env.REACT_APP_API}/answers`, {
            ...dataToSend
        }).then(function (response) {
            // console.log( response.data);
        })

        setquestionNumber(questionNumber + 1);
    };

    const name = personname;
    return (

        // defer type="text/jsx"
        <>

            {(id && finish === '') &&
                <Helmet>

                    <script type="module" defer src="/heartquiz.js"></script>
                </Helmet>
            }
            <video playsInline autoPlay className='video-element1' ref={videoRefQuestion} id="webcam" width="640" height="480"></video>
            <canvas ref={canvasRefQuestion} id='canvas_heart' width='640' className='video-element' height='480'></canvas>
            {loading && <div className="loading">
                <div id="react-logo" className="img_loading" />
            </div>}
            <div style={{ display: 'flex', justifyContent: 'center', fontSize: '1.875rem' }}>
                <h2 style={{ margin: '0 auto', zIndex: '0', fontSize: '1.875rem', position: "absolute" }} className="block__itWorks__header__title">{AboveQuestionText}</h2>
            </div>

            <div style={{ opacity: loading ? '0' : '1' }} className="module-window">



                <div className="module-window__numbers">
                    <p className="number">{t('result_table_question')} {activeQuestion + 1} з {data.length}</p>
                </div>

                <div className="module-window__questions">
                    <h6 className="module-window__question">{data[activeQuestion].title[i18n.language].replace('NAME', name)}</h6>
                    <p className="module-window__push">{data[activeQuestion].pushNote[i18n.language] ? data[activeQuestion].pushNote[i18n.language].replace('NAME', name) : ''}</p>
                    <div className="module-window__answers" ref={radiosWrapper}>
                        {
                            data[activeQuestion].answers.map((answer, index) => (

                                <div className='radio' key={index}>
                                    <input
                                        className='input'
                                        type="radio"
                                        name='answer'
                                        value={answer.text[i18n.language].replace('NAME', name)}
                                        id={index}
                                        onChange={() => { changeHandler(answer.text, answer.points) }}
                                    />
                                    <label className="radio-label" htmlFor={index}>
                                        {answer.text[i18n.language].replace('NAME', name)}
                                    </label>
                                </div>
                            )

                            )
                        }

                    </div>
                </div>
                <div className="module-window__footer">
                    {error && <p className="error">{error}</p>}
                    <Button
                        className='btn-rose'
                        onClick={nextClickHandler}
                        onKeyUp={handleKeyUp}
                    >{t('quizNext')}</Button>

                </div>
            </div>
        </>
    )
}

export default Questions