import React from 'react'
import Visa from "./../../assets/EndResult/VISA.png";
import Mastercard from "./../../assets/EndResult/MasterCard.png";
import './Footer.scss';
import { useTranslation } from 'react-i18next';
const Footer = () => {

    const {t,i18n} = useTranslation();

    return (
        <footer className='footer'>
            <div className="container">
                <div className="footer__elements">
                    <ul className='footer__elements__list'>
                        <li> <a href='/politica' className="footer__elements__list__item">{t('policy')}</a></li>
                        <li> <a href='/oferta' className="footer__elements__list__item">{t('oferta')}</a></li>
                        <li> <img className='footer_image' src={Mastercard} alt="Mastercard" /></li>
                        <li> <img className='footer_image' src={Visa} alt="VISA" /></li>
                    </ul>
                    <p className="footer__elements__copyright">Copyright @ 2022 BeSure</p>
                </div>

            </div>
        </footer>
    )
}

export default Footer